import { IFaqData, IHeroVideos, IMoreFaqData, INews, IVideo } from '../types';

// all regex
export const emailRegex = /^([a-z\d._-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:;|_~`/-])/;
export const durationRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

export const videos: IVideo[] = [
  {
    video: '/image/slider/video1.mp4',
  },
  {
    video: '/image/slider/video2.mp4',
  },
  {
    video: '/image/slider/video1.mp4',
  },
];

export const HeroVideos: IHeroVideos[] = [
  // {
  //   video: '/image/home/AGS-banner.jpg',
  // },
  // {
  //   video: "/image/home/GK-&-bKash-Cashback-Website-Banner-[December-2024].jpg",
  // },
  // {
  //   video: "/image/home/GK-MN-2-Web-Banner.jpg",
  // },
  {
    video: '/image/home/Main-Website-Banner-[31-Dec-2024].jpg',
  },
  {
    video: '/image/home/HeaderCover1.jpg',
  },
  {
    video: '/image/home/HeaderCover2.jpg',
  },
  {
    video: '/image/home/HeaderCover3.jpg',
  },
  {
    video: '/image/home/HeaderCover4.jpg',
  },
]

export const HeroVideosBn: IHeroVideos[] = [
  // {
  //   video: '/image/home/AGS-bannerBn.jpg',
  // },
  // {
  //   video: "/image/home/GK-&-bKash-Cashback-Website-Banner-[December-2024].jpg",
  // },
  // {
  //   video: "/image/home/GK-MN-2-Web-Banner.jpg",
  // },
  {
    video: '/image/home/Main-Website-Banner-[31-Dec-2024].jpg',
  },
  {
    video: '/image/home/HeaderCoverBn1.jpg',
  },
  {
    video: '/image/home/HeaderCoverBn2.jpg',
  },
  {
    video: '/image/home/HeaderCoverBn3.jpg',
  },
  {
    video: '/image/home/HeaderCoverBn4.jpg',
  },
]

export const NewsEN: INews[] = [
  {
    title: 'Prothom Alo',
    image:'/image/media/Prothom-Alo-[11-Nov-2024].jpg',
    link:'https://www.prothomalo.com/business/corporate/ktj57fo6w4'
  },
  
  {
    title: 'The Business Standard',
    image:'/image/media/news1.jpg',
    link:'https://www.tbsnews.net/economy/corporates/gold-kinen-bangladeshs-first-certified-22-karat-gold-app-providing-digital-access'
  },
  {
    title: 'Bangladesh Pratidin',
    image:'/image/media/Bangladesh-Pratidin-[25-Dec-2024].jpg',
    link:'https://www.bd-pratidin.com/sports/2024/12/25/1065320'
  },
  
  
  {
    title: 'Dhaka Tribune',
    image:'/image/media/news5.jpg',
    link:'https://www.dhakatribune.com/amp/business/345294/buy-save-gold-via-bkash-payment-from-%E2%80%98gold-kinen%E2%80%99 '
  },
  
  {
    title: 'The Financial Express',
    image:'/image/media/The-Financial-Express-Bangladesh-[23-Nov-2024]-Paper-Ads-Thumbnail.jpg',
    link:'https://today.thefinancialexpress.com.bd/views-reviews/why-now-is-a-good-time-to-invest-in-gold-1732379317'
  },
  {
    title: 'Kaler Kantha',
    image:'/image/media/Kalerkantha-[25-Dec-2024].jpg',
    link:'https://www.kalerkantho.com/online/sport/2024/12/25/1461249'
  },
  {
    title: 'The Daily Star',
    image:'/image/media/news2.jpg',
    link:'https://www.thedailystar.net/tech-startup/news/bangladeshi-app-lets-you-buy-sell-and-store-gold-digitally-3299426'
  },
  {
    title: 'Dhaka Tribune',
    image:'/image/media/Dhaka-Tribune-[05-Dec-2024].jpg',
    link:'https://www.dhakatribune.com/showtime/367053/my-%E2%80%98ajab-si%E2%80%99-moment'
  },
  {
    title: 'The Financial Express',
    image:'/image/media/The-Financial-Express-Bangladesh-[11-Nov-2024].jpg',
    link:'https://today.thefinancialexpress.com.bd/trade-market/gold-kinen-launches-monthly-gold-savings-feature-1731347604?amp=true'
  },
  {
    title: 'Daily Sun',
    image:'/image/media/Daily-Sun-[25-Dec-2024].jpg',
    link:'https://www.daily-sun.com/post/783754'
  },
  {
    title: 'Daily Jugantor',
    image:'/image/media/news8.jpg',
    link:'https://www.jugantor.com/tp-it-world/876256'
  },
  {
    title: 'The Business Standard',
    image:'/image/media/The-Business-Standard-[11-Nov-2024].jpg',
    link:'https://www.tbsnews.net/economy/corporates/gold-kinen-bangladeshs-first-certified-22-karat-gold-app-providing-digital-access'
  },
  {
    title: 'Jai Jai Din',
    image:'/image/media/news4.jpg',
    link:'https://www.jaijaidinbd.com/information-technology/411972'
  },
  {
    title: 'Dhaka Tribune',
    image:'/image/media/Dhaka-Tribune-[11-Nov-2024].jpg',
    link:'https://www.dhakatribune.com/amp/business/364861/gold-kinen-launches-bangladesh%E2%80%99s-first-monthly'
  },
  {
    title: 'Dhaka Post',
    image:'/image/media/news6.jpg',
    link:'https://www.dhakapost.com/amp/economy/275842'
  },
  {
    title: 'The Business Post',
    image:'/image/media/news7.jpg',
    link:'https://businesspostbd.com/business-connect/making-gold-affordable-accessible-for-all-via-app-2023-05-21#google_vignette'
  },
  {
    title: 'The Daily Star',
    image:'/image/media/news3.jpg',
    link:'https://www.thedailystar.net/news/bangladesh/news/day-the-girl-child-shaathi-gold-kinen-hold-art-competition-3440986'
  },

]

export const NewsBN: INews[] = [
  {
    title: 'Prothom Alo',
    image:'/image/media/Prothom-Alo-[11-Nov-2024].jpg',
    link:'https://www.prothomalo.com/business/corporate/ktj57fo6w4'
  },
  
  {
    title: 'The Business Standard',
    image:'/image/media/news1.jpg',
    link:'https://www.tbsnews.net/economy/corporates/gold-kinen-bangladeshs-first-certified-22-karat-gold-app-providing-digital-access'
  },
  {
    title: 'Bangladesh Pratidin',
    image:'/image/media/Bangladesh-Pratidin-[25-Dec-2024].jpg',
    link:'https://www.bd-pratidin.com/sports/2024/12/25/1065320'
  },
  
  
  {
    title: 'Dhaka Tribune',
    image:'/image/media/news5.jpg',
    link:'https://www.dhakatribune.com/amp/business/345294/buy-save-gold-via-bkash-payment-from-%E2%80%98gold-kinen%E2%80%99 '
  },
  
  {
    title: 'The Financial Express',
    image:'/image/media/The-Financial-Express-Bangladesh-[23-Nov-2024]-Paper-Ads-Thumbnail.jpg',
    link:'https://today.thefinancialexpress.com.bd/views-reviews/why-now-is-a-good-time-to-invest-in-gold-1732379317'
  },
  {
    title: 'Kaler Kantha',
    image:'/image/media/Kalerkantha-[25-Dec-2024].jpg',
    link:'https://www.kalerkantho.com/online/sport/2024/12/25/1461249'
  },
  {
    title: 'The Daily Star',
    image:'/image/media/news2.jpg',
    link:'https://www.thedailystar.net/tech-startup/news/bangladeshi-app-lets-you-buy-sell-and-store-gold-digitally-3299426'
  },
  {
    title: 'Dhaka Tribune',
    image:'/image/media/Dhaka-Tribune-[05-Dec-2024].jpg',
    link:'https://www.dhakatribune.com/showtime/367053/my-%E2%80%98ajab-si%E2%80%99-moment'
  },
  {
    title: 'The Financial Express',
    image:'/image/media/The-Financial-Express-Bangladesh-[11-Nov-2024].jpg',
    link:'https://today.thefinancialexpress.com.bd/trade-market/gold-kinen-launches-monthly-gold-savings-feature-1731347604?amp=true'
  },
  {
    title: 'Daily Sun',
    image:'/image/media/Daily-Sun-[25-Dec-2024].jpg',
    link:'https://www.daily-sun.com/post/783754'
  },
  {
    title: 'Daily Jugantor',
    image:'/image/media/news8.jpg',
    link:'https://www.jugantor.com/tp-it-world/876256'
  },
  {
    title: 'The Business Standard',
    image:'/image/media/The-Business-Standard-[11-Nov-2024].jpg',
    link:'https://www.tbsnews.net/economy/corporates/gold-kinen-bangladeshs-first-certified-22-karat-gold-app-providing-digital-access'
  },
  {
    title: 'Jai Jai Din',
    image:'/image/media/news4.jpg',
    link:'https://www.jaijaidinbd.com/information-technology/411972'
  },
  {
    title: 'Dhaka Tribune',
    image:'/image/media/Dhaka-Tribune-[11-Nov-2024].jpg',
    link:'https://www.dhakatribune.com/amp/business/364861/gold-kinen-launches-bangladesh%E2%80%99s-first-monthly'
  },
  {
    title: 'Dhaka Post',
    image:'/image/media/news6.jpg',
    link:'https://www.dhakapost.com/amp/economy/275842'
  },
  {
    title: 'The Business Post',
    image:'/image/media/news7.jpg',
    link:'https://businesspostbd.com/business-connect/making-gold-affordable-accessible-for-all-via-app-2023-05-21#google_vignette'
  },
  {
    title: 'The Daily Star',
    image:'/image/media/news3.jpg',
    link:'https://www.thedailystar.net/news/bangladesh/news/day-the-girl-child-shaathi-gold-kinen-hold-art-competition-3440986'
  },

]

export const faqData: IFaqData[] = [
  {
    "title": "What type of gold does Gold Kinen use?",
    "body": "We provide 22 Karat Hallmarked and Certified gold coins and bars."
  },
  {
    "title": "What is the Purity of Gold offered by Gold Kinen?",
    "body": "We provide 22 Karat Gold (91.6% pure)."
  },
  {
    "title": "How do I access Gold Kinen account on the web?",
    "body": "Currently we are not offering our transactional services through our web portal."
  },
  {
    "title": "Is nominee mandatory for Gold Kinen?",
    "body": "Yes, assigning a nominee is mandatory in Gold Kinen App."
  },
  {
    "title": "Can I send gold overseas?",
    "body": "No. You cannot send gold overseas."
  },
  {
    "title": "How do I know how much my gold is worth?",
    "body": "The worth of your gold is based on the official market price of gold for the day that is shown in the Gold Kinen app."
  },
  {
    "title": "How do I know the gold market rates are genuine?",
    "body": "Our gold rates are determined by the official rates published by BAJUS. You can go to BAJUS website and verify."
  },
  {
    "title": "Where is my gold stored?",
    "body": "Your gold is secured in high-security vaults located in Bangladesh which is operated by one of the country’s leading security companies. The name of the security company shall not be disclosed by Gold Kinen due to the product and the client’s security measures."
  },
  {
    "title": "Do you charge for the storage of my gold?",
    "body": "Any charge for storage for the gold is included in the service fee you pay when you’re collecting gold in the Gold Kinen app."
  },
  {
    "title": "How do I know my gold is secure?",
    "body": "Your gold is secured in high-security vaults located in Bangladesh which is managed by one of the top security companies in the country. Furthermore, the gold is fully insured by one of the country’s leading insurance companies (Green Delta Insurance)."
  },
  {
    "title": "How do I know my gold is genuine?",
    "body": "We provide 22 karat hallmarked gold coins and bars which are certified by our certification partner Bangla Gold."
  },
  {
    "title": "Is my Gold insured?",
    "body": "Your gold is fully insured by one of the country’s leading insurance companies, Green Delta Insurance."
  },
  {
    "title": "Which are the organizations which certifies the gold offered by Gold Kinen?",
    "body": "Our gold coins and bars are certified by leading certification agency(s), i.e. Bangla Gold."
  },
  {
    "title": "Who will manage customer complaints?",
    "body": "Customer complaints are handled by our dedicated Customer Services team. You may contact our customer care team by calling 09610964653. Or you can tap on the “Support” tab on your Gold Kinen app homescreen to contact our customer services team."
  },
  {
    "title": "How do I report a technical problem to Gold Kinen?",
    "body": "You may contact our customer care team by calling 09610964653. Or you can tap on the “Support” tab on your Gold Kinen app homescreen to contact our customer services team."
  }
]
;


export const moreFaqData: IMoreFaqData[] = [
  {
    "title": "What type of gold does Gold Kinen use?",
    "body": "We provide 22 Karat Hallmarked and Certified gold coins and bars."
  },
  {
    "title": "What is the Purity of Gold offered by Gold Kinen?",
    "body": "We provide 22 Karat Gold (91.6% pure)."
  },
  {
    "title": "How do I access Gold Kinen account on the web?",
    "body": "Currently we are not offering our transactional services through our web portal."
  },
  {
    "title": "Is nominee mandatory for Gold Kinen?",
    "body": "Yes, assigning a nominee is mandatory in Gold Kinen App."
  },
  {
    "title": "Can I send gold overseas?",
    "body": "No. You cannot send gold overseas."
  },
  {
    "title": "How do I know how much my gold is worth?",
    "body": "The worth of your gold is based on the official market price of gold for the day that is shown in the Gold Kinen app."
  },
  {
    "title": "How do I know the gold market rates are genuine?",
    "body": "Our gold rates are determined by the official rates published by BAJUS. You can go to BAJUS website and verify."
  },
  {
    "title": "Where is my gold stored?",
    "body": "Your gold is secured in high-security vaults located in Bangladesh which is operated by one of the country’s leading security companies. The name of the security company shall not be disclosed by Gold Kinen due to the product and the client’s security measures."
  },
  {
    "title": "Do you charge for the storage of my gold?",
    "body": "Any charge for storage for the gold is included in the service fee you pay when you’re collecting gold in the Gold Kinen app."
  },
  {
    "title": "How do I know my gold is secure?",
    "body": "Your gold is secured in high-security vaults located in Bangladesh which is managed by one of the top security companies in the country. Furthermore, the gold is fully insured by one of the country’s leading insurance companies (Green Delta Insurance)."
  },
  {
    "title": "How do I know my gold is genuine?",
    "body": "We provide 22 karat hallmarked gold coins and bars which are certified by our certification partner Bangla Gold."
  },
  {
    "title": "Is my Gold insured?",
    "body": "Your gold is fully insured by one of the country’s leading insurance companies, Green Delta Insurance."
  },
  {
    "title": "Which are the organizations which certifies the gold offered by Gold Kinen?",
    "body": "Our gold coins and bars are certified by leading certification agency(s), i.e. Bangla Gold."
  },
  {
    "title": "Who will manage customer complaints?",
    "body": "Customer complaints are handled by our dedicated Customer Services team. You may contact our customer care team by calling 09610964653. Or you can tap on the “Support” tab on your Gold Kinen app homescreen to contact our customer services team."
  },
  {
    "title": "How do I report a technical problem to Gold Kinen?",
    "body": "You may contact our customer care team by calling 09610964653. Or you can tap on the “Support” tab on your Gold Kinen app homescreen to contact our customer services team."
  },
  //FEES & SERVICE CHARGES 
  {
    "title": "How much does it cost to open an account in Gold Kinen?",
    "body": "Setting up a Gold Kinen account is free. There are fees associated with your transactions that are detailed on the app at the time of the transaction. You can also check our latest schedule of charges (available in the app and website)."
  },
  {
    "title": "Is there any charges for the services offered in Gold Kinen?",
    "body": "Yes. Please check our latest schedule of charges (available in the app and website)."
  },
  {
    "title": "What are the fees and service charges of Gold Kinen?",
    "body": "Please check our latest schedule of charges (available in the app and website)."
  },
  {
    "title": "How much does it cost to collect physical gold from Gold Kinen?",
    "body": "There is a service charge for collecting gold. Furthermore, delivery fees would be applicable subject to the products weight and quantity. Please check the schedule of charges section in the app and the website for all information on charges for availing Gold Kinen’s service(s)."
  },
  {
    "title": "What are the fees associated with each payment option?",
    "body": "Please check our latest schedule of charges (available in the app and website) to find all associated charges incurred with our services."
  },
  //DOWNLOAD & REGISTRATION
  {
    "title": "How do I download Gold Kinen App?",
    "body": "The Gold Kinen App is available on Android (version 7 and above) and iOS (version 11 and above). \nFor Android: Open the Google Play Store website or the Google Play Store on your Android smartphone > Search for 'Gold Kinen' > Tap 'Install'. \nFor iOS: Open the App Store > Search for 'Gold Kinen' > Tap 'Get'."
  },
  {
    "title": "Why is my personal information and NID required for opening a Gold Kinen account?",
    "body": "We require your personal and NID information to authenticate and validate the ownership of your account. We have designed the Gold Kinen experience to prevent the misuse of your account or unauthorized access to your account. This will ensure a much safer experience for you, while also allowing you to buy, sell, collect, or gift gold."
  },
  {
    "title": "What is the minimum criteria to open a Gold Kinen account?",
    "body": "To open a Gold Kinen account, you must be 18 years or above, and reside in Bangladesh where we operate and have a valid NID."
  },
  {
    "title": "I can’t take a picture for my identification/NID using camera while in Gold Kinen app. What should I do?",
    "body": "To make sure that your current app settings are optimal, you can check to see if permission for Camera is enabled for Gold Kinen App. If you are still facing issues, you are requested to contact our customer care team by calling 09610964653."
  },
  {
    "title": "How can one change their registered mobile number on Gold Kinen?",
    "body": "You cannot change your registered mobile number."
  },
  //COLLECT GOLD
  {
    "title": "What is the minimum amount of gold that you can collect?",
    "body": "The minimum amount of gold that can be collected via the app is 1gm gold bar."
  },
  {
    "title": "How do I know that the gold I collected is authentic?",
    "body": "You shall receive an invoice of authentication along with your collected gold coin and/or bar, provided by our authentication partner Bangla Gold."
  },
  {
    "title": "How to collect physical gold?",
    "body": "The physical gold can be collected via Collect gold option, starting from 1 gram."
  },
  {
    "title": "How does the gold get delivered to me?",
    "body": "You can collect your saved gold in the Gold Kinen app via Home Delivery (within Dhaka City Corporation) and Pick-up service all over Bangladesh. When opting for home delivery option, your gold will be delivered to the designated address (within Dhaka City Corporation) provided in your Gold Kinen App, during checkout, by our secured and insured delivery partners. In case of Pick-up services, your gold will be delivered to your selected pick-up point from where the user can self-collect upon arrival."
  },
  {
    "title": "Should the customer need to show any identity proof at the time of taking the physical gold coins?",
    "body": "Customers will be required to show their NID, while receiving delivery."
  },
  {
    "title": "What is the limit for collecting gold in a day?",
    "body": "Currently there is no limit for collecting gold in a day."
  },
  {
    "title": "Can I change my delivery address?",
    "body": "You can change your delivery address from the settings option on your homescreen. Tap on the settings button > Tap on Delivery Info > Tap on Edit to change your given Delivery details. Please note, your delivery options for Collect Gold will remain locked until the requested change has been approved, meaning you cannot opt to Collect Gold until the edit request has been accepted and authorized."
  },
  {
    "title": "Are there any charges for delivery of gold?",
    "body": "There is a service charge for collecting gold. Furthermore, delivery fees would be applicable subject to the product's weight and quantity. Please check the schedule of charges section in the app and the website for all information on charges for availing Gold Kinen’s service(s)."
  },
  {
    "title": "How does Gold Kinen ensure secured delivery of gold?",
    "body": "Gold Kinen ensures secured delivery via appointing one of the country’s leading Logistics Companies. All our deliveries are fully insured."
  },
  {
    "title": "Can someone else redeem the physical gold on my behalf?",
    "body": "No one can redeem the physical gold on your behalf. You must redeem physical gold yourself through the collect gold option in your app."
  },
  {
    "title": "What happens if my gold gets lost during delivery?",
    "body": "As the deliveries are fully insured, for any unfortunate event that the gold gets lost, the customer will be refunded after the necessary investigation, given that the event is not due to the customer’s negligence."
  },
  {
    "title": "Can I see my gold?",
    "body": "Yes, you can! When you use our 'Collect Gold' service to take the physical gold as delivery at your doorstep, starting from 1 gram."
  },
  {
    "title": "Can I withdraw my gold physically?",
    "body": "You can withdraw physical gold using the 'Collect Gold' service to take the physical gold as delivery at your doorstep, starting from 1 gram."
  },
  //TRANSFER/GIFT
  {
    "title": "How do I gift gold?",
    "body": "You can gift gold by selecting the 'Gift Gold' option on the Gold Kinen App. However, the receiver must have a Gold Kinen account."
  },
  {
    "title": "Is there any limit to how much gold can you gift in a day?",
    "body": "Currently, there is no limit on gifting gold in a day."
  },
  {
    "title": "What if someone doesn’t accept the gift?",
    "body": "Gifted Gold is directly transferred to the recipient’s account. There is currently no option for not accepting Gift Gold."
  },
  {
    "title": "Is there any expiry date for gifts?",
    "body": "No, there is no expiry date for gifts."
  },
  {
    "title": "How can I check the status of the gifts shared?",
    "body": "Any Gift sent/received will be notified via notification in the inbox of your Gold Kinen App. You can also check the gifted gold details from the gift tab in the history option of the Gold Kinen App."
  },
  {
    "title": "Is there any way to track the gold sent and received?",
    "body": "Any Gift sent/received will be notified via in-app notification. You can also check the gifted gold details from the gift tab in the history option of the Gold Kinen App."
  },
  {
    "title": "Can I gift gold to a recipient who does not have a Gold Kinen account?",
    "body": "No, currently you cannot Gift Gold to a person who does not have a Gold Kinen account."
  },
  {
    "title": "How is the gold rate calculated for gifting gold?",
    "body": "The gold rate will be applied as per the day’s market rate as mentioned in the Gold Kinen app. This rate can be viewed directly from the BAJUS website that is free for all."
  },
  {
    "title": "What are the charges for gifting gold?",
    "body": "Gift Gold is free of charges."
  },
  {
    "title": "How do I know if someone has received the gold I sent?",
    "body": "As all the Gift Gold requests are processed in real time, in-app notifications indicate that the gold has been transferred to the recipient. You can also check the Gifted gold details from the gift gold tab in the history option of the Gold Kinen App."
  },
  {
    "title": "I sent some gold to a new user, but they never signed up. What happens now?",
    "body": "If the recipient of your gold is not a registered Gold Kinen user, the transaction will not be successful."
  },
  {
    "title": "What happens if I send gold to the wrong person?",
    "body": "All transactions initiated by you on the Gold Kinen app are irreversible. You are solely responsible for the transactions initiated by you."
  },
  //BUY & SELL GOLD 
  {
    "title": "What is the time limit of buying gold in the app?",
    "body": "You can buy gold from the app from 10:00am to 11:59pm every day."
  },
  {
    "title": "Is there any time limit of when I can sell gold via the app?",
    "body": "You can sell your gold anytime within 24 hours a day."
  },
  {
    "title": "How to buy gold in Gold Kinen?",
    "body": "You can purchase gold in Gold Kinen via the 'Buy Gold' option by inputting the amount/quantity you want to buy and confirming your payment through a mobile wallet."
  },
  {
    "title": "How many times can I buy gold in a day?",
    "body": "You can buy gold unlimited times in a day."
  },
  {
    "title": "How much gold can I buy from my Gold Kinen account in a single transaction?",
    "body": "You can buy up to BDT 300,000 (Three lac) worth of gold in a single transaction."
  },
  {
    "title": "What is the buy price of gold?",
    "body": "The buy price of the gold is the BAJUS declared price every day. For more information on price please visit https://www.bajus.org/"
  },
  {
    "title": "What is the processing time for buying gold?",
    "body": "Buying gold transactions are processed immediately."
  },
  {
    "title": "Can I buy gold with other currencies except BDT?",
    "body": "No. You can only buy gold with the currency of the country we currently operate in, which is BDT."
  },
  {
    "title": "What is the minimum amount in BDT needed to buy gold on Gold Kinen App?",
    "body": "The minimum amount needed to buy gold in Gold Kinen app is BDT 500."
  },
  {
    "title": "Where is the gold stored when you buy gold?",
    "body": "The gold is stored in a storage facility secured by a leading storage provider of Bangladesh. Furthermore, your gold is fully insured by one of the most renowned insurance companies of Bangladesh."
  },
  {
    "title": "What if I enter the wrong amount when buying gold?",
    "body": "You can buy up to the amount covered by the available BDT balance of your mobile wallet or the limit/available balance in your debit/credit card. Once a transaction is executed, it cannot be reversed."
  },
  {
    "title": "What if I enter the wrong amount when buying or selling gold?",
    "body": "Once a gold buy/sell transaction is executed in the Gold Kinen app, it cannot be reversed."
  },
  {
    "title": "Do I have to connect my bank account to avail Gold Kinen app services?",
    "body": "You do not have to connect your bank account to avail ALL the Gold Kinen app services. Bank account/mobile wallet is only needed to avail the sell gold option."
  },
  {
    "title": "How long does it take to process the amount of sold gold?",
    "body": "Refund/sold amount of gold is credited to the customer’s account within 3 working days (as per BEFTN & Bangladesh Bank rules). Any delays are subject to delays at the payment gateway or financial institution’s end."
  },
  {
    "title": "What happens if the sales proceeds are not credited to me within the maximum processing days?",
    "body": "If you do not receive the refund/sold amount to your designated mobile wallet/bank account within the stipulated timeline, you are requested to communicate with the customer care team by calling 09610964653."
  },
  {
    "title": "Where can I take the money from sold/refund amount of gold from Gold Kinen app?",
    "body": "You can receive the money of your sold/refund amount of gold via mobile wallet or your bank account."
  },
  {
    "title": "How many times a day can I sell gold?",
    "body": "You can sell gold an unlimited number of times in a day."
  },
  {
    "title": "What is the maximum sell gold amount from my Gold Kinen account?",
    "body": "You can sell gold up to the available gold balance amount from your account (subject to applicable charges and bank/mobile banking guidelines)."
  },
  {
    "title": "What is the minimum amount of gold I can sell from my Gold Kinen account?",
    "body": "You can sell any amount of the available gold balance from your account (subject to applicable charges and bank/mobile banking guidelines)."
  },
  {
    "title": "I sold gold but the amount has not yet arrived at my bank account/mobile wallet, what should I do?",
    "body": "If you do not receive the refund/sold gold amount within the stipulated timeline (3 working days) in your designated bank account/mobile wallet, you are requested to communicate immediately with the customer care team by calling 09610964653."
  },
  {
    "title": "Can I sell my own existing gold via the Gold Kinen app?",
    "body": "Sorry, no. You can only sell gold bought from within the app, via the Gold Kinen app."
  },
  //MY ACCOUNT 
  {
    "title": "I already have a Gold Kinen account on my mobile. How do I access it on the web?",
    "body": "Currently, we are not offering our transactional services through our web portal."
  },
  {
    "title": "Can I get a Gold Kinen account without a mobile phone? / Does it work from my desktop?",
    "body": "No. Gold Kinen currently works on mobile phones (Android and iOS) only."
  },
  {
    "title": "Can I have a joint account for Gold Kinen?",
    "body": "No, you cannot have a joint account for Gold Kinen."
  },
  {
    "title": "How do I update my phone number?",
    "body": "Sorry. You cannot update your phone number associated with the Gold Kinen App."
  },
  {
    "title": "How do I connect my bank account with Gold Kinen app?",
    "body": "You can connect your bank account from Settings > Payment info - Bank. While setting up your bank account, you will be required to provide Account Name, Account Number, Bank Name, District, and Branch Name."
  },
  {
    "title": "What happens to Gold Kinen account if the account holder dies?",
    "body": "The legal heir of the account holder can claim the account holder’s account by following the account handover process. In such cases, they are requested to contact Gold Kinen customer care by calling 09610964653. According to the Laws and Regulations set by the Bangladesh Govt., the nominee shall only be registered as the account’s caretaker, not the inheritor of the deceased account holder."
  },
  {
    "title": "How can I get my Gold Kinen account statement?",
    "body": "You can check the Records option for transaction history."
  },
  {
    "title": "I’m not able to input my bank account details, what should I do?",
    "body": "For delays/interruption in bank account verification, you are requested to contact our customer care team by calling 09610964653."
  },
  //PROMO CODE, REFERRAL & REWARDS 
  {
    "title": "What is a refer code?",
    "body": "Refer code is the unique number used to identify each user of GOLD KINEN. The unique refer code is used to track and distribute any program rewards to the customer and his/her referral (the referred customer)."
  },
  {
    "title": "What is a Promo code?",
    "body": "Promo Codes are a defined phrase and number designated to avail promotional offers set by Gold Kinen."
  },
  {
    "title": "How can I refer my friends?",
    "body": "You can refer a friend by selecting the ‘Refer a friend’ from the homepage of the Gold Kinen app or you can share your unique refer code with the person you want to refer."
  },
  {
    "title": "What is the reward for referral?",
    "body": "After a successful referral upon the first gold purchase of the referee, both the referee and referrer may get a referral bonus, which may vary from time to time."
  },
  {
    "title": "How can I check that I have received my reward by referral?",
    "body": "We will send you a notification when you receive a reward in your inbox in your Gold Kinen app."
  },
  {
    "title": "I have not received my reward, why?",
    "body": "If you have not received your reward after applying the promo/refer code within the stipulated time, please contact Gold Kinen customer care at 09610964653."
  },
  {
    "title": "My promo code/refer code did not work, why?",
    "body": "If you see an error message after you applied your promo/refer code, it may be because: \n1) The promo code has expired \n2) You do not meet the campaign criteria to use the promo/refer code \n3) The promo/refer code is invalid \nFor any other reason, please contact Gold Kinen customer care at 09610964653."
  },
  {
    "title": "How long does it take to receive my reward after my referee used Gold Kinen app successfully?",
    "body": "You will receive the reward by the end of the given month upon the fulfillment of referral conditions."
  },
  {
    "title": "Can I use one Gold Kinen promo code concurrently with other Gold Kinen Promo Codes?",
    "body": "No, you can only use one code at a time."
  },
  //PIN, PASSWORDS & OTP
  {
    "title": "During transaction, why have I been asked for an authentication/OTP code?",
    "body": "You have been asked for an authentication/OTP code during transactions as an additional security measure."
  },
  {
    "title": "How do I set up 2-factor authentication?",
    "body": "Currently, we are using only PIN to access the app. Two-factor authentication, or 2-step verification, is an additional security measure used only in the registration process and while logging in from a new device."
  },
  {
    "title": "What happens if I’ve forgotten my PIN?",
    "body": "You are requested to contact Gold Kinen customer care by calling 09610964653 and initiate the PIN reset process."
  },
  {
    "title": "What if I want to change my PIN?",
    "body": "You may reset your PIN from Homescreen > Settings > PIN Reset on the Gold Kinen app. For further assistance, please call our customer service number, 09610964653."
  },
  {
    "title": "What happens if I’ve entered the wrong PIN?",
    "body": "You will have 5 attempts at login. If you’ve entered the incorrect PIN more than 5 times, your PIN will be disabled. For recovering your account, you are requested to contact our customer care team by calling 09610964653."
  },
  {
    "title": "How can I set up my PIN?",
    "body": "During registration, you will be asked to set and confirm your PIN after the phone number verification via OTP. You may reset your PIN from Homescreen > Settings > PIN Reset on the Gold Kinen app."
  },
  //SUPPORT
  {
    "title": "How do I change the language on the app?",
    "body": "You can switch the language between English & Bangla from your app’s Settings > Language Toggle on the top right (Bangla/English) and choose your preferred language."
  },
  {
    "title": "I’ve lost my mobile phone, what should I do?",
    "body": "If such an unfortunate event happens, kindly contact our Customer Support Team immediately by calling 09610964653 and request to ‘lock’ your Gold Kinen account."
  },
  {
    "title": "I’ve changed my mobile phone, how can I access my Gold Kinen account from my new mobile device?",
    "body": "Download the Gold Kinen app on your new mobile phone and recover your account. For further assistance in recovering your account, call our customer service number, 09610964653."
  },
  {
    "title": "I’m trying to complete my profile on the Gold Kinen app, but I’m facing issues, what should I do?",
    "body": "In case you are facing any issues with completing/updating your profile, please refer to our website or you may contact our customer care team by calling 09610964653."
  },
  {
    "title": "How do I change my email address registered with Gold Kinen?",
    "body": "You can change your email address from the profile section in the Gold Kinen app."
  },
  {
    "title": "Why is my account locked?",
    "body": "There may be multiple reasons for which your account is locked. To know the details, you are requested to contact our customer care team by calling 09610964653."
  },
  //AUTO GOLD SAVE 
  {
    "title": "What is AUTO GOLD SAVE?",
    "body": "AUTO GOLD SAVE by Gold Kinen is a feature that enables users to purchase and save desired amounts of 22K Hallmarked & Certified gold automatically in their vaults. Users can set up monthly payments via their bKash payment method, with amounts ranging from BDT 1,000 to BDT 50,000. The tenure options are 3, 6, or 12 months."
  },
  {
    "title": "How to start AUTO GOLD SAVE plan?",
    "body": "Log in to your Gold Kinen account, tap on the AUTO GOLD SAVE button, enter plan details, select tenure, verify information, enter PIN, and tap Pay to start your AUTO GOLD SAVE journey."
  },
  {
    "title": "How does AUTO GOLD SAVE work?",
    "body": "The Gold Kinen App AUTO GOLD SAVE plan helps users set up a monthly gold saving scheme; where users can purchase and save 22K gold starting from BDT 1000 via automated bKash payments monthly for a selected period of time (3, 6, 12 months)."
  },
  {
    "title": "What are the tenures for AUTO GOLD SAVE?",
    "body": "The tenure options for AUTO GOLD SAVE plan are 3, 6, or 12 months."
  },
  {
    "title": "What is the minimum amount to start AUTO GOLD SAVE?",
    "body": "The minimum amount to start the AUTO GOLD SAVE plan is BDT 1000."
  },
  {
    "title": "What is the maximum amount to start AUTO GOLD SAVE?",
    "body": "The maximum amount to start the AUTO GOLD SAVE plan is BDT 50,000."
  },
  {
    "title": "What are the payment methods for AUTO GOLD SAVE?",
    "body": "Currently, Gold Kinen App users can start their AUTO GOLD SAVE plans using bKash payment method."
  },
  {
    "title": "How many AUTO GOLD SAVE plans can be active altogether?",
    "body": "Users can have a maximum of 5 AUTO GOLD SAVE plans active simultaneously."
  },
  {
    "title": "What happens if I miss an AUTO GOLD SAVE payment?",
    "body": "If payment fails on the designated date, further payment attempts will be made as per the policy of the selected payment gateway (bKash). If all attempts fail within payment gateway's tenure, then the user's payment for the stated month will be considered a failure. The AUTO GOLD SAVE plan will continue for the next month as scheduled."
  },
  {
    "title": "How to cancel AUTO GOLD SAVE plan?",
    "body": "Log in to your Gold Kinen account, tap on AUTO GOLD SAVE, select 'More Details' for the plan you wish to cancel, then tap on 'Cancel Auto Gold Save Plan' at the bottom of the screen."
  },
  {
    "title": "Will there be VAT deduction while making AUTO GOLD SAVE purchase?",
    "body": "Yes. 5% VAT will be added to each month's gold purchase as per Govt. directive. VAT deduction is a mandatory government directive, and Gold Kinen is compliant with this directive."
  },
  {
    "title": "Can I collect or sell the gold saved with the AUTO GOLD SAVE plan while the plan is ongoing?",
    "body": "Yes. Any user can collect or sell the gold stored in their vault at any time, despite ongoing AUTO GOLD SAVE plans."
  },
  {
    "title": "Is it possible to change AUTO GOLD SAVE tenure once the plan has already started?",
    "body": "No. AUTO GOLD SAVE plans cannot be changed after they have already begun. Users can cancel existing plans if necessary."
  }
]
export const faqDataBn: IFaqData[] = [
  {
    title: 'গোল্ড কিনেন-এ কি ধরনের গোল্ড ব্যবহৃত হয়?',
    body: 'গোল্ড কিনেন ২২ক্যারাটের হলমার্ক প্রত্যয়িত গোল্ড কয়েন এবং গোল্ড বার ব্যবহার করে।',
  },
  {
    title: 'গোল্ড কিনেন দ্বারা অফারকৃত গোল্ডের বিশুদ্ধতা কত?',
    body: 'গোল্ড কিনেন, ৯১.৬% খাঁটি, ২২ ক্যারেট গোল্ড সরবরাহ করে।',
  },
  {
    title: 'আমি কীভাবে আমার গোল্ড কিনেন অ্যাকাউন্ট ওয়েবসাইট-এ অ্যাক্সেস করব?',
    body: 'বর্তমানে আমরা ওয়েব পোর্টালের মাধ্যমে আমাদের কোনো লেনদেন সংক্রান্ত পরিষেবা অফার করছি না।',
  },
  {
    title: 'গোল্ড কিনেন অ্যাপের জন্য নমিনী কি বাধ্যতামূলক?',
    body: 'হ্যাঁ, গোল্ড কিনেন অ্যাপে একজন নমিনী নিয়োগ করা বাধ্যতামূলক৷',
  },
  {
    title: 'আমি কি বিদেশে গোল্ড পাঠাতে পারি?',
    body: 'না। আপনি বিদেশে গোল্ড পাঠাতে পারবেন না।',
  },
  {
    title: 'আমি কিভাবে জানবো আমার গোল্ডের এখনকার মূল্য কত?',
    body: 'আজকের দিনের অফিসিয়াল বাজার মূল্যের উপর ভিত্তি করে, আপনার গোল্ড ব্যালেন্সের এখনকার মূল্য নির্ধারিত হবে, যা গোল্ড কিনেন অ্যাপে দেখানো হয়েছে।',
  },
  {
    title: 'আমি কিভাবে জানব যে গোল্ডের প্রদত্ত বাজারের রেট আসল?',
    body: 'আমাদের গোল্ডের দর বাজুস কর্তৃক প্রকাশিত অফিসিয়াল রেট দ্বারা নির্ধারিত হয়। আপনি বাজুস ওয়েবসাইটে যেয়ে এই ব্যাপারে নিশ্চিত হতে পারবেন।',
  },
  {
    title: 'আমার গোল্ড কোথায় জমা আছে?',
    body: 'আপনার গোল্ড বাংলাদেশে অবস্থিত উচ্চস্তরের নিরাপত্তা ভল্টে সুরক্ষিত আছে যা দেশের একটি শীর্ষস্থানীয় নিরাপত্তা সংস্থা দ্বারা পরিচালিত। গোল্ড কিনেন এবং গ্রাহকের নিরাপত্তার স্বার্থে নিরাপত্তা সংস্থার নাম প্রকাশ করা সম্ভব না।',
  },
  {
    title: 'আপনি কি আমার গোল্ড স্টোরেজের জন্য চার্জ করবেন?',
    body: 'গোল্ড কিনেন অ্যাপে গোল্ড উত্তোলনের সময় আপনি যে সার্ভিস চার্জ প্রদান করেন তার মধ্যে গোল্ড স্টোরেজ চার্জ অন্তর্ভুক্ত থাকে।',
  },
  {
    title: 'আমি কীভাবে জানব যে আমার গোল্ড নিরাপদে আছে?',
    body: 'আপনার গোল্ড বাংলাদেশে অবস্থিত একটি উচ্চতর নিরাপদ ভল্টে সুরক্ষিত রয়েছে যা দেশের একটি শীর্ষ নিরাপত্তা সংস্থার দ্বারা পরিচালিত। অধিকন্তু, দেশের অন্যতম প্রধান বীমা কোম্পানি (গ্রীন ডেল্টা ইন্সুরেন্স) দ্বারা আপনার গোল্ড সম্পূর্ণরূপে বীমাকৃত।',
  },
  {
    title: 'আমি কীভাবে জানব যে আমার গোল্ড আসল কি না?',
    body: 'গোল্ড কিনেন আপনাকে সম্পূর্ণরূপে হলমার্ক প্রত্যয়িত ২২ ক্যারেট গোল্ড কয়েন এবং গোল্ড বার সরবরাহ করে। আমাদের সকল সার্টিফিকেশন বাংলা গোল্ড দ্বারা প্রত্যয়িত।',
  },
  {
    title: 'আমার গোল্ড কি বীমাকৃত?',
    body: 'হ্যা! আপনার গোল্ড বীমাকৃত। দেশের শীর্ষস্থানীয় বীমা কোম্পানি, গ্রীন ডেল্টা ইন্সুরেন্স, দ্বারা আপনার গোল্ড সম্পূর্ণ বীমা করা আছে।',
  },
  {
    title: 'গোল্ড কিনন দ্বারা প্রদত্ত গোল্ডের প্রত্যয়নকারী সংস্থাগুলি কোনটি?',
    body: 'আমাদের গোল্ড কয়েন এবং গোল্ড বার বাংলা গোল্ড দ্বারা প্রত্যয়িত।'
  },
  {
    title: 'গ্রাহকের অভিযোগ পরিচালনা করার দায়িত্ব কার?',
    body: 'গ্রাহকের অভিযোগ আমাদের নিবেদিত গ্রাহক পরিষেবা দল (কাস্টমার সার্ভিস টীম) দ্বারা পরিচালিত হয়। আপনি 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন। অথবা আপনার গোল্ড কিনেন অ্যাপ হোমস্ক্রীনে "সাপোর্ট" ট্যাবে ট্যাপ করে আপনি আমাদের কাস্টমার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন।',
  },
  {
    title: 'গোল্ড কিনেনের কোনো টেকনিক্যাল (প্রযুক্তিগত) সমস্যা রিপোর্ট করব কিভাবে?',
    body: 'গোল্ড কিনেন অ্যাপে কোনো প্রকারের সমস্যা হলে 09610964653 কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন। অথবা আপনার গোল্ড কিনেন অ্যাপ হোমস্ক্রীনে "সাপোর্ট" ট্যাবে ট্যাপ করে আপনি আমাদের কাস্টমার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন।',
  },
];




// export const moreFaqData: IMoreFaqData[] = [{
//   body: "We provide 22 Karat Hallmarked and Certified gold coins and bars",
//   title: "  What type of gold does Gold Kinen use?"
// }, {
//   body: "We provide 22 Karat Gold (91.6% pure).",
//   title: "  What is the Purity of Gold offered by Gold Kinen?"
// }, {
//   body: "The Gold Kinen App is available on Android (version 7 and above) and iOS (version 8 and above)",
//   title: "  How do I download Gold Kinen App?"
// }, {
//   body: "Currently we are not offering our transactional services through our web portal.",
//   title: "  I already have a Gold Kinen account on my mobile"
// }, {
//   body: "KYC means Know Your Customer and sometimes Know Your Client. KYC or KYC check is the mandatory process of identifying and verifying the client's identity when opening an account and periodically over time. The safety and security of your gold is always our highest priority, and we are continuously working to improve these aspects. We have designed the Gold Kinen experience to prevent the misuse of your account or unauthorized access to your gold. This will ensure a much safer experience for you, while also allowing you to buy, sell, collect or gift gold.",
//   title: "  What is KYC? Why is KYC needed?"
// }, {
//   body: "No, assigning a nominee is not mandatory in Gold Kinen App.",
//   title: "  Is nominee mandatory for Gold Kinen?"
// }, {
//   body: "Setting up a Gold Kinen account is free. There are fees associated with your transactions that are detailed on the app at the time of the transaction. You can also check our latest <a href='/${locale}/scheduled-charges'><u>schedule of charges</u>.</a>",
//   title: "  How much does it cost to open an account in Gold Kinen?"
// }, {
//   body: "No. Gold Kinen currently works on mobile phones(iOS/Android) only.",
//   title: "  Can I get Gold Kinen account without a mobile phone? / Does it work from my desktop?"
// }, {
//   body: "Yes. Please check our latest <a href='/${locale}/scheduled-charges'><u>schedule of charges</u>.</a>",
//   title: "  Is there any charges for the services offered in Gold Kinen?"
// }, {
//   body: "To open a Gold Kinen account you must be 18 years or above, and reside in Bangladesh where we operate.",
//   title: "  What is the minimum criteria to open a Gold Kinen account?"
// }, {
//   body: "You have been asked for an authentication/OTP code during transactions as an additional security measure.",
//   title: "  During transaction, why have I been asked for an authentication/ OTP code?"
// }, {
//   body: "No, you cannot have a joint account for Gold Kinen.",
//   title: "  Can I have a joint account for Gold Kinen?"
// }, {
//   body: "Please note that users may only update their phone numbers via communicating customer care team by calling 09610964653 ",
//   title: "  How do I update my phone number?"
// }, {
//   body: "You can switch the language between English &amp; Bangla from Settings >Language Toggle (Bangla/English)",
//   title: "  How do I change language on the app?"
// }, {
//   body: "Currently we are using only PIN to access the app. Two-factor authentication, or 2-step verification, is an additional security measure used only in registration process and while logging in from a new device",
//   title: "  How do I set up 2-factor authentication?"
// }, {
//   body: "You can connect your bank account from Settings > Profile > Payment. While setting up your bank account you will be required to provide Account Name, Account Number, Bank Name, District, Branch Name. ",
//   title: "  How do I connect my bank account with Gold Kinen app?"
// }, {
//   body: "You can purchase gold in Gold Kinen via Buy Gold option by inputting the amount/quantity you want to buy and confirming your payment through mobile wallet or Debit/Credit card.",
//   title: "  How to buy gold in Gold Kinen?"
// }, {
//   body: "You can buy unlimited times in a day.",
//   title: "  How many times I can buy gold in a day?"
// }, {
//   body: "You can buy up to BDT 300,000 worth of gold in a single transaction",
//   title: "  How much gold can I buy from my Gold Kinen account in a single transaction? "
// }, {
//   body: "Buy price of the gold is the BAJUS declared price every day. For more information on price please visit https://www.bajus.org/ ",
//   title: "  What is a buy price of Gold?"
// }, {
//   body: "Buying gold transactions are processed immediately.",
//   title: "  What is the processing time for buying gold?"
// }, {
//   body: "You do not have to connect your bank account to avail all the Gold Kinen app services. Bank Account/Mobile wallet is only needed to avail the sell gold option. ",
//   title: "  Do I have to connect my bank account to avail Gold Kinen app services?"
// }, {
//   body: "No. You can only buy gold with the currency of the country we currently operate in. Which is BDT.",
//   title: "  Can I buy gold with other currencies except BDT?"
// }, {
//   body: "The minimum amount needed to buy gold in Gold Kinen app is BDT  500. ",
//   title: "  What is the minimum amount in BDT needed to buy gold on Gold Kinen App?"
// }, {
//   body: "The gold is stored in a storage facility secured by a leading storage provider of Bangladesh. Furthermore, your gold is fully insured by one of the most renowned Insurance companies of Bangladesh.",
//   title: "  Where is the gold stored when you buy gold?"
// }, {
//   body: "You can only buy up to the amount covered by the available BDT balance of your Mobile wallet/Bank account/Credit Card. Once a transaction is executed it can not be reversed.",
//   title: "  What if I enter the wrong amount when buying gold?"
// }, {
//   body: "Refund/sold amount of gold is credited within 3 working days(as per BEFTN &amp; Bangladesh Bank rules). Any delays are subject to delays at payment gateway or financial institution’s end.",
//   title: "  How long does it take to process the amount of sold gold?"
// }, {
//   body: "If you do not receive the refund/sold amount to your designated mobile wallet/bank account within stipulated timeline you are requested to communicate Customer Care team by calling 09610964653 ",
//   title: "  What happens if the sales proceeds are not credited to me the maximum processing days?"
// }, {
//   body: "You can receive the money of your sold/refund amount of gold via mobile wallet and banking channel.",
//   title: "  Where can I take the money from sold/refund amount of gold from Gold Kinen app?"
// }, {
//   body: "You can sell gold unlimited number of times in a day.",
//   title: "  How many times a day can I sell gold? "
// }, {
//   body: "You can sell gold up to the available gold balance amount from your account (subject to applicable charges and bank/mobile banking guideline).",
//   title: "  What is the maximum sell gold amount from my Gold Kinen account? "
// }, {
//   body: "Minimum gold you can sell is BDT 500 of the available gold balance amount from your account (subject to applicable charges and bank/mobile banking guideline).",
//   title: "  What is the minimum amount of gold can I sell from my Gold Kinen account? "
// }, {
//   body: "Once a gold buy/sell transaction is executed in the Gold Kinen app, it cannot be reversed.",
//   title: "  What if I enter the wrong amount when buying or selling gold?"
// }, {
//   body: "If you do not receive the refund/sold gold amount within stipulated timeline (3 working days) in your designated bank account/mobile wallet, you are requested to communicate immediately with Customer Care team by calling 09610964653 ",
//   title: "  I sold gold but the amount has not yet arrived at my bank account/mobile wallet, what should I do? "
// }, {
//   body: "The physical gold can be collected via Collect gold option.",
//   title: "  How to collect physical gold?"
// }, {
//   body: "Your gold will be delivered to your designated address given in your Gold Kinen App, by our secured and insured delivery partners.",
//   title: "  How does the gold get delivered to me?"
// }, {
//   body: "Customers will be required to show their NID, while receiving delivery.",
//   title: "  Should the customer need to show any identity proof at the time of taking the physical gold coins?"
// }, {
//   body: "Currently there is no limit for collecting gold in a day.",
//   title: "  What is the limit for collecting gold in a day?"
// }, {
//   body: "You can change your delivery address from Profile section. However, your account will remain locked until the requested change has been approved.",
//   title: "  Can I change my delivery address?"
// }, {
//   body: "There is 2% service charge for Collecting Gold on the gold value of the day's market price. Additionally, Insured delivery charges will be applicable as per the Schedule of charges",
//   title: "  Are there any charges for delivery of gold?"
// }, {
//   body: "Gold Kinen ensures secured delivery via appointing one of the country’s leading logistics company. All our deliveries are fully insured.",
//   title: "  How does Gold Kinen ensure secured delivery of gold?"
// }, {
//   body: "No one can redeem the physical gold on your behalf. You must redeem physical gold yourself through the collect gold option in your app. ",
//   title: "  Can someone else redeem the physical gold on my behalf?"
// }, {
//   body: "As the deliveries are fully insured, for any unfortunate event that the gold gets lost, customer will be refunded after the necessary investigation, given that the event is not due to customer’s negligence.",
//   title: "  What happens if my gold gets lost during delivery?"
// }, {
//   body: "You can gift gold by selecting the “Gift Gold” option on the Gold Kinen App",
//   title: "  How do I gift gold?"
// }, {
//   body: "Currently there is no limit on Gift gold in a day.",
//   title: "  Is there any limit to how much gold can you gift in a day?"
// }, {
//   body: "Gifted Gold is directly transferred to the recipient’s account. There is currently no option for not accepting Gift Gold. ",
//   title: "  What if someone doesn’t accept the gift?"
// }, {
//   body: "No, there is no expiry date for gifts.",
//   title: "  Is there any expiry date for gifts?"
// }, {
//   body: "Any Gift sent/received will be notified via notification in your inbox of your Gold Kinen App. You can also check the Gifted gold details from the gift tab in the history option of the Gold Kinen App.",
//   title: "  How can I check the status of the gifts shared?"
// }, {
//   body: "Any Gift sent/received will be notified via in app notification. You can also check the Gifted gold details from the gift tab in history option of the Gold Kinen App.",
//   title: "  Is there any way to track the gold sent and received?"
// }, {
//   body: "No currently you cannot Gift Gold to a person who does not have a Gold Kinen account",
//   title: "  Can I gift gold to a recipient who does not have a Gold Kinen account?"
// }, {
//   body: "Currently Gift Gold is free of charges. The gold rate will be applied as per the day’s market rate as mentioned in the Gold Kinen app.",
//   title: "  How is the gold rate calculated for gifting gold?"
// }, {
//   body: "No. You cannot send gold overseas.",
//   title: "  Can I send gold overseas?"
// }, {
//   body: "As all the Gift Gold requests are processed in real time, In app notification indicates that the gold has been transferred to the recipient. You can also check the Gifted gold details from the gift gold tab in the history option of the Gold Kinen App.",
//   title: "  How do I know if someone has received the gold I sent?"
// }, {
//   body: "If the recipient of your gold is not a registered Gold Kinen user, the transaction will not be successful.",
//   title: "  I sent some gold to a new user, but they never signed up"
// }, {
//   body: "All transactions initiated by you on the Gold Kinen app are irreversible. You are solely responsible for the transactions initiated by you. ",
//   title: "  What happens if I send gold to the wrong person?"
// }, {
//   body: "Customers will be required to show the valid NID, while receiving delivery.",
//   title: "  Should the receiver need to show any identity proof at the time of taking the physical gold?"
// }, {
//   body: "Please check our latest <a href='/${locale}/scheduled-charges'><u>schedule of charges</u>.</a>.",
//   title: "  What are the fees and service charges of Gold Kinen?"
// }, {
//   body: "Currently there are no charges for collecting gold in Gold Kinen. However, delivery fees will be applicable as per the scheduled of charges of delivery partners.",
//   title: "  How much does it cost to collect physical gold from Gold Kinen? "
// }, {
//   body: "Please check our latest <a href='/${locale}/scheduled-charges'><u>schedule of charges</u>.</a>",
//   title: "  What are the fees associated with each payment option? "
// }, {
//   body: "No. You can only buy gold with the currency of the country we currently operate in. Which is BDT.",
//   title: "  How do I know how much my gold is worth?"
// }, {
//   body: "The charge for storage for the gold is included in the service fee you pay when you’re buying gold in the Gold Kinen app.",
//   title: "  Do you charge for the storage of my gold?"
// }, {
//   body: "Our gold rates are determined by the official rates published by BAJUS. You can go to BAJUS website and match their rates with our rates.",
//   title: "  How do I know the gold market rates are genuine?"
// }, {
//   body: "Your gold is secured in high-security vaults located in Bangladesh which is operated by one of the country’s leading security companies.",
//   title: "  Where is my gold stored?"
// }, {
//   body: "Yes, you can! When you use our “Collect Gold” service to take the physical gold as delivery at your doorstep.",
//   title: "  Can I see my gold?"
// }, {
//   body: "You can withdraw physical gold using “Collect Gold” service to take the physical gold as delivery at your doorstep.",
//   title: "  Can I withdraw my gold physically?"
// }, {
//   body: "Your gold is secured in high-security vaults located in Bangladesh which is managed by one of the top security companies in the country. Furthermore, the gold is fully insured by one of the country’s leading insurance companies.",
//   title: "  How do I know my gold is secure?"
// }, {
//   body: "You are entitled to the gold you buy and store at Gold Kinen. In the unlikely event that something was to happen to Gold Kinen, your gold will be refunded or delivered to you.",
//   title: "  What happens to my gold if anything happens to Gold Kinen?"
// }, {
//   body: "We provide 22 karat hallmarked gold coins and bars which are certified by our certification partner Bangla Gold/Dhaka Gold.",
//   title: "  How do I know my gold is genuine?"
// }, {
//   body: "Your gold is fully insured by one of the country’s leading insurance companies.",
//   title: "  Is my Gold insured?"
// }, {
//   body: "Our gold coins and bars are certified by Bangla Gold/Dhaka Gold.",
//   title: "  Which are the organizations which certifies the gold offered by Gold Kinen?"
// }, {
//   body: "The legal heir of the account holder can claim the account holder’s account by following the account handover process. In such cases they are requested to contact Gold Kinen customer care by calling 09610964653.",
//   title: "  What happens to Gold Kinen account if the account holder dies?"
// }, {
//   body: "You can refer a friend by selecting the “Refer a friend” from the homepage from the Gold Kinen app or you can share your unique refer code to the person you want to refer. ",
//   title: "  How can I refer my friends?"
// }, {
//   body: "After a successful referral upon the first buy gold of the referee, both referee and referrer may get referral bonus, which may vary from time to time.",
//   title: "  What is the reward for referral?"
// }, {
//   body: "We will send you a notification when you receive a reward in your inbox in your Gold Kinen app. ",
//   title: "  How can I check that I have received my reward by referral?"
// }, {
//   body: "If you have not received your reward after applying the promo/refer code within stipulated time, please contact Gold Kinen customer care at 09610964653.",
//   title: "  I have not received my reward, why?"
// }, {
//   body: "If you see an error message after you applied your promo/refer code, it may be because:1) the promo code has expired2) you do not meet the campaign criteria to use the promo/refer code3) The promo/refer code is invalidFor any other reason please contact Gold Kinen customer care at 09610964653.",
//   title: "  My promo code/refer code did not work, why?"
// }, {
//   body: "You would receive the reward within ten working days of the fulfillment of referral conditions.",
//   title: "  How long does it take to receive my reward after my referee used Gold Kinen app successfully?"
// }, {
//   body: "No, you can only use one code at a time.",
//   title: "  Can I use one Gold Kinen promo code concurrently with other Gold Kinen Promo Codes?"
// }, {
//   body: "Customer complaints are handled by our dedicated Customer Services team. You may contact our customer care team by calling 09610964653. ",
//   title: "  Who will manage Customer complaints?"
// }, {
//   body: "You may contact our customer care team by calling 09610964653",
//   title: "  How do I report a technical problem to Gold Kinen?"
// }, {
//   body: "If such an unfortunate event happens, kindly contact our Customer Support Team immediately by calling 09610964653 and request to “lock” your Gold Kinen account. ",
//   title: "  I’ve lost my mobile phone, what should I do?"
// }, {
//   body: "Download the Gold Kinen app on your new mobile phone and recover your account. TECH TEAM ",
//   title: "  I’ve changed my mobile phone, how can I access my Gold Kinen account from my new mobile device?"
// }, {
//   body: "You are requested to contact Gold Kinen customer care by calling 09610964653 and initiate PIN replacement process.",
//   title: "  What happens if I’ve forgotten my PIN?"
// }, {
//   body: "You may reset your PIN from Profile  > Settings  > Login Preferences on the Gold Kinen app.",
//   title: "   What if I want to change my PIN?"
// }, {
//   body: "You will have 5 attempts at login. If you’ve entered the incorrect PIN more than 5 times, your PIN will be disabled. For recovering your account you are requested to contact our customer care team by calling 09610964653",
//   title: "  What happens if I’ve entered the wrong PIN?"
// }, {
//   body: "During registration, you will be asked to set and confirm your PIN after the phone number verification via OTP. You may reset your PIN from Profile  > Settings  > Login Preferences on the Gold Kinen app.",
//   title: "  How can I setup my PIN?"
// }, {
//   body: "In case you are facing any issues with completing/updating your profile, you may contact our customer care team by calling 09610964653.",
//   title: "  I’m trying to complete my profile on the Gold Kinen app, but I’m facing issues, what should I do?"
// }, {
//   body: "To make sure that your current app settings are optimal, you can check to see if permission for Camera is enabled for Gold Kinen App. If you are still facing issues, you are requested to contact our customer care team by calling 09610964653. ",
//   title: "  I can’t take a picture for my identification/NID using camera while in Gold Kinen app"
// }, {
//   body: "You can check the history option for transaction history.",
//   title: "  How can I get my Gold Kinen account statement?"
// }, {
//   body: "You cannot change your registered mobile number.",
//   title: "  How can one change their registered mobile number on Gold Kinen?"
// }, {
//   body: "For delays in bank account verification, you are requested to contact our customer care team by calling 09610964653. ",
//   title: "  I’m facing issues with bank account verification, what should I do?"
// }, {
//   body: "You can change your email address from the profile section in the Gold Kinen app.",
//   title: "  How do I change my email address registered with Gold Kinen?"
// }, {
//   body: "There may be multiple reasons for which your account is locked. To know the details, you are requested to contact our customer care team by calling 09610964653. ",
//   title: "  Why is my account locked?"
// }, {
//     body: "AUTO GOLD SAVE by Gold Kinen is a feature that enables users to purchase and save desired amounts of 22K Hallmarked & Certified gold automatically in their vaults. Users can set up monthly payments via their bKash payment method, with amounts ranging from BDT 1,000 to BDT 10,000. The tenure options are 3, 6, or 12 months.",
//     title: "What is AUTO GOLD SAVE?"
//   },
//   {
//     body: "Log in to your Gold Kinen account, tap on the AUTO GOLD SAVE button, enter plan details, select tenure, verify information, enter PIN, and tap Pay to start your AUTO GOLD SAVE journey.",
//     title: "How to start AUTO GOLD SAVE plan?"
//   },
//   {
//     body: "The Gold Kinen App AUTO GOLD SAVE plan helps users set up a monthly gold saving scheme; where users can purchase and save 22K gold starting from BDT 1000 via automated bKash payments monthly for a selected period of time (3, 6, 12 months).",
//     title: "How does AUTO GOLD SAVE work?"
//   },
//   {
//     body: "The tenure options for AUTO GOLD SAVE plan are 3, 6, or 12 months.",
//     title: "What are the tenures for AUTO GOLD SAVE?"
//   },
//   {
//     body: "The minimum amount to start the AUTO GOLD SAVE plan is BDT 1000.",
//     title: "What is the minimum amount to start AUTO GOLD SAVE?"
//   },
//   {
//     body: "The maximum amount to start the AUTO GOLD SAVE plan is BDT 10,000.",
//     title: "What is the maximum amount to start AUTO GOLD SAVE?"
//   },
//   {
//     body: "Currently Gold Kinen App users can start their AUTO GOLD SAVE plans using bKash payment method.",
//     title: "What are the payment methods for AUTO GOLD SAVE?"
//   },
//   {
//     body: "Users can have a maximum of 5 AUTO GOLD SAVE plans active simultaneously.",
//     title: "How many AUTO GOLD SAVE plans can be active all together?"
//   },
//   {
//     body: "If payment fails on the designated date, further payment attempts will be made as per the policy of the selected payment gateway (bKash). If all attempts fail within payment gateway's tenure then the user's payment for the stated month will be considered a failure. The AUTO GOLD SAVE plan will continue for the next month as scheduled.",
//     title: "What happens if I miss an AUTO GOLD SAVE payment?"
//   },
//   {
//     body: "Log in to your Gold Kinen account, tap on AUTO GOLD SAVE, select 'More Details' for the plan you wish to cancel, then tap on 'Cancel Auto Gold Save Plan' at the bottom of the screen.",
//     title: "How to cancel AUTO GOLD SAVE plan?"
//   },
//   {
//     body: "Yes. 5% VAT will be added to each month's gold purchase as per Govt. directive. VAT deduction is a mandatory government directive, and Gold Kinen is compliant to this directive.",
//     title: "Will there be VAT deduction while making AUTO GOLD SAVE purchase?"
//   },
//   {
//     body: "Yes. Any user can collect or sell the gold stored in their vault at any time, despite ongoing AUTO GOLD SAVE plans.",
//     title: "Can I collect or sell the gold saved with the AUTO GOLD SAVE plan while the plan is ongoing?"
//   },
//   {
//     body: "No. AUTO GOLD SAVE plans cannot be changed after they have already begun. Users can cancel existing plans if necessary.",
//     title: "Is it possible to change AUTO GOLD SAVE tenure once plan has already started?"
//   }
// ]


export const moreFaqDataBn: IMoreFaqData[] = [
  {
    title: 'গোল্ড কিনেন-এ কি ধরনের গোল্ড ব্যবহৃত হয়?',
    body: 'গোল্ড কিনেন ২২ক্যারাটের হলমার্ক প্রত্যয়িত গোল্ড কয়েন এবং গোল্ড বার ব্যবহার করে।',
  },
  {
    title: 'গোল্ড কিনেন দ্বারা অফারকৃত গোল্ডের বিশুদ্ধতা কত?',
    body: 'গোল্ড কিনেন, ৯১.৬% খাঁটি, ২২ ক্যারেট গোল্ড সরবরাহ করে।',
  },
  {
    title: 'আমি কীভাবে আমার গোল্ড কিনেন অ্যাকাউন্ট ওয়েবসাইট-এ অ্যাক্সেস করব?',
    body: 'বর্তমানে আমরা ওয়েব পোর্টালের মাধ্যমে আমাদের কোনো লেনদেন সংক্রান্ত পরিষেবা অফার করছি না।',
  },
  {
    title: 'গোল্ড কিনেন অ্যাপের জন্য নমিনী কি বাধ্যতামূলক?',
    body: 'হ্যাঁ, গোল্ড কিনেন অ্যাপে একজন নমিনী নিয়োগ করা বাধ্যতামূলক৷',
  },
  {
    title: 'আমি কি বিদেশে গোল্ড পাঠাতে পারি?',
    body: 'না। আপনি বিদেশে গোল্ড পাঠাতে পারবেন না।',
  },
  {
    title: 'আমি কিভাবে জানবো আমার গোল্ডের এখনকার মূল্য কত?',
    body: 'আজকের দিনের অফিসিয়াল বাজার মূল্যের উপর ভিত্তি করে, আপনার গোল্ড ব্যালেন্সের এখনকার মূল্য নির্ধারিত হবে, যা গোল্ড কিনেন অ্যাপে দেখানো হয়েছে।',
  },
  {
    title: 'আমি কিভাবে জানব যে গোল্ডের প্রদত্ত বাজারের রেট আসল?',
    body: 'আমাদের গোল্ডের দর বাজুস কর্তৃক প্রকাশিত অফিসিয়াল রেট দ্বারা নির্ধারিত হয়। আপনি বাজুস ওয়েবসাইটে যেয়ে এই ব্যাপারে নিশ্চিত হতে পারবেন।',
  },
  {
    title: 'আমার গোল্ড কোথায় জমা আছে?',
    body: 'আপনার গোল্ড বাংলাদেশে অবস্থিত উচ্চস্তরের নিরাপত্তা ভল্টে সুরক্ষিত আছে যা দেশের একটি শীর্ষস্থানীয় নিরাপত্তা সংস্থা দ্বারা পরিচালিত। গোল্ড কিনেন এবং গ্রাহকের নিরাপত্তার স্বার্থে নিরাপত্তা সংস্থার নাম প্রকাশ করা সম্ভব না।',
  },
  {
    title: 'আপনি কি আমার গোল্ড স্টোরেজের জন্য চার্জ করবেন?',
    body: 'গোল্ড কিনেন অ্যাপে গোল্ড উত্তোলনের সময় আপনি যে সার্ভিস চার্জ প্রদান করেন তার মধ্যে গোল্ড স্টোরেজ চার্জ অন্তর্ভুক্ত থাকে।',
  },
  {
    title: 'আমি কীভাবে জানব যে আমার গোল্ড নিরাপদে আছে?',
    body: 'আপনার গোল্ড বাংলাদেশে অবস্থিত একটি উচ্চতর নিরাপদ ভল্টে সুরক্ষিত রয়েছে যা দেশের একটি শীর্ষ নিরাপত্তা সংস্থার দ্বারা পরিচালিত। অধিকন্তু, দেশের অন্যতম প্রধান বীমা কোম্পানি (গ্রীন ডেল্টা ইন্সুরেন্স) দ্বারা আপনার গোল্ড সম্পূর্ণরূপে বীমাকৃত।',
  },
  {
    title: 'আমি কীভাবে জানব যে আমার গোল্ড আসল কি না?',
    body: 'গোল্ড কিনেন আপনাকে সম্পূর্ণরূপে হলমার্ক প্রত্যয়িত ২২ ক্যারেট গোল্ড কয়েন এবং গোল্ড বার সরবরাহ করে। আমাদের সকল সার্টিফিকেশন বাংলা গোল্ড দ্বারা প্রত্যয়িত।',
  },
  {
    title: 'আমার গোল্ড কি বীমাকৃত?',
    body: 'হ্যা! আপনার গোল্ড বীমাকৃত। দেশের শীর্ষস্থানীয় বীমা কোম্পানি, গ্রীন ডেল্টা ইন্সুরেন্স, দ্বারা আপনার গোল্ড সম্পূর্ণ বীমা করা আছে।',
  },
  {
    title: 'গোল্ড কিনন দ্বারা প্রদত্ত গোল্ডের প্রত্যয়নকারী সংস্থাগুলি কোনটি?',
    body: 'আমাদের গোল্ড কয়েন এবং গোল্ড বার বাংলা গোল্ড দ্বারা প্রত্যয়িত।',
  },
  {
    title: 'গ্রাহকের অভিযোগ পরিচালনা করার দায়িত্ব কার?',
    body: 'গ্রাহকের অভিযোগ আমাদের নিবেদিত গ্রাহক পরিষেবা দল (কাস্টমার সার্ভিস টীম) দ্বারা পরিচালিত হয়। আপনি 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন। অথবা আপনার গোল্ড কিনেন অ্যাপ হোমস্ক্রীনে "সাপোর্ট" ট্যাবে ট্যাপ করে আপনি আমাদের কাস্টমার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন।',
  },
  {
    title: 'গোল্ড কিনেনের কোনো টেকনিক্যাল (প্রযুক্তিগত) সমস্যা রিপোর্ট করব কিভাবে?',
    body: 'গোল্ড কিনেন অ্যাপে কোনো প্রকারের সমস্যা হলে 09610964653 কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন। অথবা আপনার গোল্ড কিনেন অ্যাপ হোমস্ক্রীনে "সাপোর্ট" ট্যাবে ট্যাপ করে আপনি আমাদের কাস্টমার সার্ভিস টিমের সাথে যোগাযোগ করতে পারেন।',
  },
  //FEES & SERVICE CHARGES //
  {
    title: 'গোল্ড কিনেন অ্যাপে একটি অ্যাকাউন্ট খুলতে কত খরচ হয়?',
    body: 'আপনার গোল্ড কিনেন অ্যাকাউন্ট সেট আপ হবে একদম বিনামূল্যে। আপনার লেনদেনের সাথে সম্পর্কিত যে সব ফি রয়েছে তার বর্ণনা লেনদেনের সময়, অ্যাপে বিস্তারিত ভাবে বলে দেয়া আছে । এছাড়াও আপনি আমাদের চার্জের সর্বশেষ আপডেট জানতে  অ্যাপ এবং ওয়েবসাইটে দেখে নিন ।',
  },
  {
    title: 'গোল্ড কিনেন অ্যাপে প্রদত্ত পরিষেবাগুলির জন্য কি কোন চার্জ আছে?',
    body: 'হ্যাঁ। অনুগ্রহ করে আমাদের চার্জ সমূহের সর্বশেষ তালিকা (অ্যাপ এবং ওয়েবসাইটে উপলব্ধ) দেখে নিন ।',
  },
  {
    title: 'গোল্ড কিনেনের ফি এবং সার্ভিস চার্জ কি কি?',
    body: 'আমাদের সর্বশেষ চার্জের তালিকা চেক করুন (অ্যাপ এবং ওয়েবসাইটে উপলব্ধ)।',
  },
  {
    title: 'গোল্ড কিনেন থেকে গোল্ড কালেক্ট/সংগ্রহ করতে কত খরচ হয়?',
    body: 'গোল্ড উত্তোলনের জন্য একটি সার্ভিস চার্জ প্রযোজ্য। পাশাপাশি, পণ্যের ওজন এবং পরিমাণ সাপেক্ষে একটি ডেলিভারি চার্জ প্রযোজ্য হবে।  গোল্ড কিনেনের পরিষেবা ব্যবহারের জন্য চার্জ সংক্রান্ত সমস্ত তথ্যের জন্য অনুগ্রহ করে অ্যাপ এবং ওয়েবসাইটে "সার্ভিস চার্জ সমূহ"  তালিকাটি দেখুন।',
  },
  {
    title: 'প্রতিটি পেমেন্ট অপশনের সাথে সম্পর্কিত চার্জ কি কি?',
    body: 'আমাদের সর্বশেষ চার্জের তালিকা সমূহ দেখুন এখানে।',
  },
  //DOWNLOAD & REGISTRATION//
  {
    title: 'আমি কিভাবে গোল্ড কিনেন অ্যাপ ডাউনলোড করব?',
    body: 'গোল্ড কিনেন অ্যাপ অ্যান্ড্রয়েড (ভার্সন 7 এবং ঊর্ধ্বে ) -এ  উপলব্ধ ও iOS (ভার্সন ১১এবং ঊর্ধ্বে) -এ  উপলব্ধ।\nঅ্যান্ড্রয়েডের জন্য: আপনার অ্যান্ড্রয়েড স্মার্টফোনে গুগল প্লে স্টোর  খুলুন> "Gold Kinen" সার্চ করুন> "ইনস্টল” করুন\niOS এর জন্য: অ্যাপ স্টোর খুলুন> "Gold Kinen" সার্চ করুন> “Get” বাটনে ট্যাপ করুন।',
  },
  {
    title: 'গোল্ড কিনেন অ্যাপে অ্যাকাউন্ট খোলার জন্য কেন আমার ব্যক্তিগত তথ্য এবং NID প্রয়োজন?',
    body: 'আপনার অ্যাকাউন্টের মালিকানা প্রমাণীকরণ এবং যাচাই করার জন্য আমাদের আপনার ব্যক্তিগত এবং NID তথ্য প্রয়োজন। আপনার অ্যাকাউন্টের অপব্যবহার বা  অননুমোদিত অ্যাক্সেস রোধ করতে আমরা এই গোল্ড কিনেন পরিষেবাটি ডিজাইন করেছি। এটি আপনার জন্য আরও নিরাপদ অভিজ্ঞতা নিশ্চিত করবে, পাশাপাশি আপনার গোল্ড ক্রয় , বিক্রয় , উত্তোলন বা উপহার দেওয়া সহজ করে তুলবে।',
  },
  {
    title: 'একটি গোল্ড কিনেন অ্যাকাউন্ট খোলার ন্যূনতম মানদণ্ড কী?',
    body: 'একটি গোল্ড কিনেন অ্যাকাউন্ট খুলতে আপনার সর্বনিম্ন বয়স হতে হবে ১৮ বছর বা তার বেশি এবং আপনাকে বাংলাদেশে বসবাস করতে হবে এবং আপনার একটি বৈধ এনআইডি (জাতীয় পরিচয়পত্র) থাকতে হবে।',
  },
  {
    title: 'গোল্ড কিনেন অ্যাপে থাকা অবস্থায় আমি ক্যামেরা ব্যবহার করে আমার শনাক্তকরণ/এনআইডির জন্য ছবি তুলতে পারছি না । আমার কি করা উচিৎ?',
    body: 'আপনার বর্তমান অ্যাপ সেটিংস সর্বোত্তর কিনা তা নিশ্চিত করতে, আপনার গোল্ড কিনেন অ্যাপের ক্যামেরার অনুমতি দেয়া আছে কিনা তা দেখে নিন । আপনি যদি এখনও সমস্যার সম্মুখীন হন, তাহলে আপনাকে 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে ।',
  },
  {
    title: 'কিভাবে কেউ গোল্ড কিনেনে তাদের নিবন্ধিত মোবাইল নম্বর পরিবর্তন করতে পারে?',
    body: 'আপনি আপনার নিবন্ধিত মোবাইল নম্বর পরিবর্তন করতে পারবেন না।',
  },
  //COLLECT GOLD//
  {
    title: "সর্বনিম্ন কত পরিমাণ থেকে গোল্ড উত্তোলন করা সম্ভব?",
    body: "গোল্ড কিনেন অ্যাপের মাধ্যমে নূন্যতম ১গ্রাম গোল্ড থেকে শুরু করে গোল্ড উত্তোলন করা সম্ভব।"
  },
  {
    title: "আমি কিভাবে জানব যে আমি যে গোল্ড উত্তোলন করেছি তা খাঁটি?",
    body: "গোল্ড কিনেন দ্বারা প্রদত্ত, গ্রাহকের উত্তোলনকৃত, প্রতিটি গোল্ড কয়েন এবং/বার-এর সাথে আমাদের অথেনটিকেশন পার্টনার, বাংলা গোল্ড দ্বারা প্রস্তুত একটি প্রত্যয়নি ইনভয়েস থাকবে।"
  },
  {
    title: "কিভাবে আপনার ক্রয়কৃত গোল্ড সংগ্রহ করবেন?",
    body: "গোল্ড কিনেন অ্যাপে গোল্ড উত্তোলন (কালেক্ট গোল্ড) অপশনের মাধ্যমে আপনার ক্রয়কৃত গোল্ড সংগ্রহ করা সম্ভব।"
  },
  {
    title: "কিভাবে গোল্ড আমার কাছে ডেলিভারি করা হবে?",
    body: "আপনি সারা বাংলাদেশে হোম ডেলিভারি (ঢাকা সিটি কর্পোরেশনের মধ্যে) এবং পিক-আপ পরিষেবার মাধ্যমে গোল্ড কিনেন অ্যাপে আপনার সঞ্চিত গোল্ড উত্তোলন করতে পারেন। হোম ডেলিভারির ক্ষেত্রে, আপনার গোল্ড কিনেন অ্যাপে চেকআউটের সময়, প্রদত্ত নির্ধারিত ঠিকানায় (ঢাকা সিটি কর্পোরেশনের মধ্যে) সুরক্ষিত এবং বীমাকৃত ডেলিভারির মাধ্যমে আপনার গোল্ড, বার বা কয়েন আকারে পৌঁছে দেওয়া হবে। পিক-আপ সার্ভিসের ক্ষেত্রে, আপনার গোল্ড আপনার নির্বাচিত পিক-আপ পয়েন্টে পৌঁছে দেওয়া হবে যেখান থেকে ব্যবহারকারী স্ব-সংগ্রহ করতে পারবেন।"
  },
  {
    title: "গোল্ড কয়েন বা গোল্ড বার নেওয়ার সময় গ্রাহককে কি কোনো পরিচয়পত্র বা প্রমাণ দেখাতে হবে?",
    body: "ডেলিভারি পাওয়ার সময় গ্রাহকদের তাদের জাতীয় পরিচয়পত্র (NID) দেখাতে হবে।"
  },
  {
    title: "একদিনে গোল্ড কালেক্ট/ উত্তোলনের সীমা কত?",
    body: "বর্তমানে একদিনে গোল্ড উত্তোলনের কোনো সীমা নেই।"
  },
  {
    title: "আমি কি আমার ডেলিভারি ঠিকানা পরিবর্তন করতে পারি?",
    body: "আপনি আপনার হোমস্ক্রীনের সেটিংস অপশন থেকে আপনার ডেলিভারি ঠিকানা পরিবর্তন করতে পারবেন। সেটিংস বাটনে ট্যাপ করুন >ডেলিভারি তথ্যে ট্যাপ করুন>আপনার প্রদত্ত ডেলিভারির ঠিকানা পরিবর্তন করতে পরিবর্তন বাটনে ট্যাপ করুন। অনুগ্রহ করে মনে রাখবেন, আপনার অনুরোধকৃত পরিবর্তনটি অনুমোদিত না হওয়া পর্যন্ত \"গোল্ড উত্তোলন\" করার জন্য আপনার ডেলিভারি অপশন লক থাকবে, অর্থাৎ পরিবর্তনের অনুরোধ গৃহীত ও অনুমোদিত না হওয়া পর্যন্ত আপনি গোল্ড উত্তোলন করতে পারবেন না।"
  },
  {
    title: "গোল্ড ডেলিভারির জন্য কি কোন চার্জ আছে?",
    body: "গোল্ড উত্তোলনের জন্য একটি সার্ভিস চার্জ প্রযোজ্য। পাশাপাশি, পণ্যের ওজন এবং পরিমাণ সাপেক্ষে একটি ডেলিভারি চার্জ প্রযোজ্য হবে। গোল্ড কিনেনের পরিষেবা ব্যবহারের জন্য চার্জ সংক্রান্ত সমস্ত তথ্যের জন্য অনুগ্রহ করে অ্যাপ এবং ওয়েবসাইটে \"সার্ভিস চার্জ সমূহ\" তালিকাটি দেখুন।"
  },
  {
    title: "গোল্ড কিনেন কীভাবে আমার গোল্ডের নিরাপদ ডেলিভারি নিশ্চিত করে?",
    body: "দেশের একটি শীর্ষস্থানীয় লজিস্টিক কোম্পানির নিয়োগের মাধ্যমে গোল্ড কিনেন আপনার আমানতের নিরাপদ ডেলিভারি নিশ্চিত করে। আমাদের সব ডেলিভারি সম্পূর্ণভাবে বীমাকৃত।"
  },
  {
    title: "আমি বাদে অন্য কেউ কি আমার পক্ষ থেকে আমার গোল্ড রিডিম/ খালাস করতে পারে?",
    body: "আপনি ব্যাতিত অন্য কেউ আপনার পক্ষ হয়ে আপনার সংগ্রহযোগ্য গোল্ড রিডিম করতে পারবে না। শুধুমাত্র আপনার অ্যাপে কালেক্ট গোল্ড অপশনের মাধ্যমেই আপনি ফিজিক্যাল গোল্ড রিডিম করতে পারবেন।"
  },
  {
    title: "ডেলিভারির সময় আমার গোল্ড হারিয়ে গেলে কি হবে?",
    body: "যেহেতু আমাদের সকল ডেলিভারি সম্পূর্ণভাবে বীমাকৃত, দুর্ভাগ্যক্রমে যদি গ্রাহকের গোল্ড হারিয়ে যায়, তাহলে গ্রাহককে প্রয়োজনীয় তদন্তের পরে গোল্ড সমপরিমাণ অর্থ রিফান্ড/ফেরত দেয়া হবে। যদি ঘটনাটি গ্রাহকের অবহেলার কারণে না ঘটে থাকে।"
  },
  {
    title: "আমি কি আমার গোল্ড দেখতে পারি?",
    body: "হ্যা, আপনি পারবেন! আপনি যখন আমাদের \"কালেক্ট গোল্ড\" পরিষেবাটি ব্যবহার করবেন, তার মাধ্যমে সর্বনিম্ন ১ গ্রাম থেকে শুরু করে আপনার কাছে সরাসরি ডেলিভারি হয়ে গোল্ড পৌঁছে যাবে।"
  },
  {
    title: "আমি কি বাস্তবে আমার গোল্ড উত্তোলন করতে পারি?",
    body: "আমাদের \"কলেক্ট গোল্ড\" পরিষেবাটি ব্যবহার করে আপনি আপনার সুবিধা অনুযায়ী স্থানে বা বাসায় আপনার সঞ্চিত গোল্ড, সর্বনিম্ন ১ গ্রাম থেকে শুরু করে, ডেলিভারি নিতে পারেন।"
  },
  // TRANSFER/GIFT//
  {
    title: "আমি কিভাবে গোল্ড গিফ্ট /উপহার দেব?",
    body: "আপনি গোল্ড কিনেন অ্যাপে \"গিফ্ট গোল্ড\" অপশনটি নির্বাচন করে কয়েকটি ধাপের মধ্যেই আপনার প্রিয়জনকে গোল্ড উপহার দিতে পারবেন। তবে প্রাপকের অবশ্যই গোল্ড কিনেন অ্যাকাউন্ট থাকতে হবে।"
  },
  {
    title: "আমি একদিনে কত গোল্ড গিফ্ট/উপহার দিতে পারবো তার কি কোনো সীমা আছে?",
    body: "বর্তমানে একদিনে \"গোল্ড গিফ্ট\" করার কোন সীমা নেই।"
  },
  {
    title: "কেউ যদি উপহারকৃত গোল্ড রিসিভ না করতে চায়?",
    body: "উপহারকৃত গোল্ড সরাসরি প্রাপকের অ্যাকাউন্টে স্থানান্তরিত হয়। তাই উপহারকৃত গোল্ড না রিসিভ করার জন্য বর্তমানে কোনো বিকল্প উপায় নেই।"
  },
  {
    title: "উপহারের জন্য কোন মেয়াদ শেষ হওয়ার তারিখ আছে কি?",
    body: "না, উপহারের কোনো মেয়াদ শেষ হওয়ার তারিখ নেই।"
  },
  {
    title: "গোল্ড উপহার দেয়ার পরে কিভাবে আমি উপহারকৃত গোল্ডের অবস্থান দেখবো?",
    body: "আপনার গোল্ড কিনেন অ্যাপের ইনবক্সে যেকোন প্রেরিত/প্রাপ্ত উপহার নোটিফিকেশনের মাধ্যমে জানানো হবে। এছাড়া আপনার গোল্ড কিনেন অ্যাপের ইতিহাস অপশনে উপহার ট্যাব থেকে গোল্ড গিফ্টের বিস্তারিত তথ্য জানতে পারেন।"
  },
  {
    title: "প্রেরিত এবং প্রাপ্ত গোল্ড ট্র্যাক করার কোন উপায় আছে কি?",
    body: "প্রেরিত/প্রাপ্ত যেকোন উপহার অ্যাপ নোটিফিকেশনের মাধ্যমে জানানো হবে। এছাড়াও আপনি গোল্ড কিনেন অ্যাপের ইতিহাস অপশনে উপহার ট্যাব থেকে \"গোল্ড গিফ্ট-এর\" বিবরণ দেখতে পারেন।"
  },
  {
    title: "আমি কি এমন একজন ব্যক্তি/প্রাপককে গোল্ড উপহার দিতে পারি যার গোল্ড কিনেন অ্যাকাউন্ট নেই?",
    body: "না। বর্তমানে আপনি এমন কাউকে গোল্ড উপহার দিতে পারবেন না যার গোল্ড কিনেন অ্যাকাউন্ট নেই।"
  },
  {
    title: "গিফ্ট গোল্ডের সময় গোল্ডের রেট/হার কীভাবে গণনা করা হয়?",
    body: "গোল্ড কিনেন অ্যাপে উল্লেখিত দিনের বাজার মূল্য অনুযায়ী গোল্ডের রেট/হার প্রয়োগ করা হবে। এই তথ্যটি বাংলাদেশ জুয়েলার্স এসোসিয়েশন (বাজুস)-এর ওয়েবসাইটে সকলের জন্য উন্মুক্ত ভাবে প্রকাশিত।"
  },
  {
    title: "গোল্ড গিফট করতে কি কোনো চার্জ আছে?",
    body: "বর্তমানে গিফ্ট গোল্ডের উপরে কোনো চার্জ নির্ধারিত নেই।"
  },
  {
    title: "আমার পাঠানো গোল্ড কেউ পেয়েছে কিনা আমি কিভাবে বুঝব?",
    body: "যেহেতু সকল \"গিফ্ট গোল্ড\" অনুরোধ রিয়েল টাইমে প্রসেস করা হয়, আপনার কাছে অ্যাপে নোটিফিকেশন আসবে যে প্রাপকের অ্যাপে গোল্ড জমা করা হয়েছে। এছাড়াও আপনি গোল্ড কিনেন অ্যাপের ইতিহাস অপশনে গিফট গোল্ড ট্যাব থেকে গিফটেড গোল্ডের বিবরণ দেখতে পারেন।"
  },
  {
    title: "আমি একটি নতুন ব্যবহারকারীর কাছে কিছু গোল্ড পাঠিয়েছি, কিন্তু তারা কখনই সাইন আপ করেনি। এখন কি হবে?",
    body: "যদি আপনার গোল্ড গিফ্টের প্রাপক একজন নিবন্ধিত গোল্ড কিনেন ব্যবহারকারী না হন, তাহলে লেনদেন সফল হবে না।"
  },
  {
    title: "ভুল ব্যক্তির কাছে গোল্ড পাঠালে কি হবে?",
    body: "গোল্ড কিনেন অ্যাপে আপনার দ্বারা সম্পন্ন সকল লেনদেন অপরিবর্তনীয়। আপনার সকল লেনদেনের জন্য আপনি সম্পূর্ণরূপে দায়ী।"
  },
  //BUY & SELL GOLD//
  {
    title: "গোল্ড কিনেন অ্যাপে গোল্ড কেনার কি কোনো সময়সীমা আছে?",
    body: "জি। আপনি প্রতিদিন সকাল ১০টা থেকে রাত ১১:৫৯ পর্যন্ত গোল্ড কিনেন অ্যাপ থেকে গোল্ড কিনতে পারবেন।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপের মাধ্যমে গোল্ড বিক্রয় করার কি কোনো সময়সীমা আছে?",
    body: "আপনি দিনে ২৪ ঘন্টায় যেকোনো সময় আপনার গোল্ড কিনেন অ্যাপ থেকে ক্রয়কৃত গোল্ড অ্যাপের মাধ্যমে বিক্রি করতে পারবেন।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপ থেকে কিভাবে গোল্ড কিনবেন?",
    body: "\"গোল্ড ক্রয়\" অপশনটি সিলেক্ট করার মাধ্যমে, আপনি যে পরিমাণ গোল্ড কিনতে চান তা প্রবেশ করে, তারপর মোবাইল ওয়ালেট-এর মাধ্যমে আপনার অর্থপ্রদান নিশ্চিত করলেই আপনি আপনার নির্ধারিত পরিমানের গোল্ড কিনতে পারবেন।"
  },
  {
    title: "আমি দিনে কতবার গোল্ড কিনতে পারি?",
    body: "আপনি একদিনে আনলিমিটেড/অগণিত বার গোল্ড কিনতে পারবেন।"
  },
  {
    title: "একক লেনদেনে আমি আমার গোল্ড কিনেন অ্যাকাউন্ট থেকে কতটুকু গোল্ড কিনতে পারি?",
    body: "আপনি একক লেনদেনে ১০০,০০০ (এক লাখ) টাকা পর্যন্ত গোল্ড কিনতে পারবেন।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপে গোল্ডের ক্রয় মূল্য কত?",
    body: "গোল্ড কিনেন অ্যাপে গোল্ডের ক্রয়মূল্য বাজুস-এর নিয়মিত ঘোষিত মূল্যে গোল্ড ক্রয় করা সম্ভব। আজকের গোল্ড বাজারমূল্যের সম্পর্কে আরও তথ্যের জন্য অনুগ্রহ করে এই লিংকে ক্লিক করুন https://www.bajus.org/"
  },
  {
    title: "গোল্ড ক্রয়ের জন্য প্রক্রিয়াকরণের সর্বনিম্ন সময়সীমা কী?",
    body: "গোল্ড ক্রয়, লেনদেন হওয়ার অবিলম্বে প্রক্রিয়াধিন হয়ে যায়।"
  },
  {
    title: "আমি কি টাকা ছাড়া অন্য কোনো মুদ্রায় গোল্ড কিনতে পারি?",
    body: "জি না। বর্তমানে আমরা যে দেশে অবস্থিত শুধুমাত্র সেই দেশের মুদ্রায় (অর্থাৎ টাকায়) আপনি গোল্ড কিনতে পারবেন।"
  },
  {
    title: "সর্বনিম্ন কত টাকায় গোল্ড কিনেন অ্যাপে গোল্ড কেনা সম্ভব?",
    body: "গোল্ড কিনেন অ্যাপে গোল্ড ক্রয় করার সর্বনিম্ন পরিমাণ ৫০০টাকা।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপ থেকে ক্রয়কৃত গোল্ড কোথায় জমা/সংরক্ষিত হয়?",
    body: "আপনার গোল্ড কিনেন অ্যাপ থেকে ক্রয়কৃত গোল্ড বাংলাদেশের একটি শীর্ষস্থানীয় স্টোরেজ প্রদানকারী দ্বারা সুরক্ষিত ভাবে সংরক্ষণ করা হয়। এছাড়াও, আপনার ক্রয়কৃত গোল্ড সম্পূর্ণরূপে বাংলাদেশের একটি অন্যতম বিখ্যাত বীমা কোম্পানি দ্বারা বীমাকৃত অবস্থায় স্টোর করা হয়।"
  },
  {
    title: "গোল্ড কেনার সময় যদি আমি ভুল পরিমাণ লিখি?",
    body: "আপনি শুধুমাত্র আপনার মোবাইল ওয়ালেট-এর অথবা ডেবিট/ক্রেডিট কার্ডে বিদ্যমান ব্যালেন্স/লিমিট যতটুক আছে সেই সাপেক্ষে সমপরিমাণ গোল্ড কিনতে পারবেন। একবার একটি লেনদেন কার্যকর করা হলে তা আর ফেরতযোগ্য নহে।"
  },
  {
    title: "গোল্ড ক্রয় বা বিক্রয়ের সময় যদি আমি ভুল পরিমাণ লিখি?",
    body: "একবার গোল্ড কিনেন অ্যাপে গোল্ড ক্রয়/বিক্রয় লেনদেন সম্পাদিত হলে, তা পরবর্তীতে ফেরতযোগ্য নহে।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপ পরিষেবাগুলি পেতে আমাকে কি আমার ব্যাংক অ্যাকাউন্ট সংযুক্ত করতে হবে?",
    body: "সকল গোল্ড কিনেন অ্যাপ পরিষেবা সমূহ পেতে আপনাকে আপনার ব্যাংক অ্যাকাউন্ট সংযোগ করতে হবে না। ব্যাংক অ্যাকাউন্ট/মোবাইল ওয়ালেট তথ্য শুধুমাত্র গোল্ড বিক্রয়ের সময় প্রয়োজন।"
  },
  {
    title: "বিক্রিত গোল্ড প্রক্রিয়াধীন অবস্থায় কতদিন থাকে?",
    body: "গোল্ডের বিক্রীত সমপরিমাণ অর্থ, অন্তত ৩ কার্যদিবসের মধ্যে (BEFTN এবং বাংলাদেশ ব্যাংকের নিয়ম অনুসারে) গ্রাহকের অ্যাকাউন্টে জমা হয়। অতিরিক্ত যেকোনো বিলম্ব পেমেন্ট গেটওয়ে বা আর্থিক প্রতিষ্ঠানের অধীনে বিলম্ব সাপেক্ষ।"
  },
  {
    title: "সর্বোচ্চ প্রক্রিয়াকরণের সময়সীমা অতিক্রম হয়ে গেলে আমার বিক্রয়কৃত অর্থ আমার কাছে জমা না হলে কী হবে?",
    body: "আপনি যদি নির্ধারিত সময়সীমার মধ্যে আপনার মনোনীত মোবাইল ওয়ালেট/ব্যাংক অ্যাকাউন্টে ফেরত/বিক্রীত সমপরিমাণ অর্থ না পেয়ে থাকেন তাহলে আপনাকে 09610964653 এ কল করে কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপ থেকে বিক্রয়কৃত সমপরিমাণ গোল্ডের অর্থ আমি কোথায় গ্রহণ করতে পারি?",
    body: "গোল্ড কিনেন অ্যাপ থেকে বিক্রয়কৃত সমপরিমাণ গোল্ডের টাকা আপনি মোবাইল ওয়ালেট এবং ব্যাংক অ্যাকাউন্টে রিসিভ করতে পারবেন।"
  },
  {
    title: "আমি দিনে কতবার গোল্ড বিক্রি করতে পারি?",
    body: "আপনি আপনার অ্যাকাউন্টে জমাকৃত গোল্ড একদিনে অপরিমিত সংখ্যক গোল্ড বিক্রি করতে পারেন।"
  },
  {
    title: "আমার গোল্ড কিনেন অ্যাকাউন্ট থেকে সর্বোচ্চ বিক্রিযোগ্য গোল্ডের পরিমাণ কত?",
    body: "আপনি আপনার অ্যাকাউন্ট থেকে উপলব্ধ গোল্ড ব্যালেন্স পরিমাণ সমান গোল্ড বিক্রি করতে পারেন (ব্যাংক /মোবাইল চার্জ প্রযোজ্য এবং ব্যাংকিং নির্দেশিকা সাপেক্ষে)।"
  },
  {
    title: "আমার গোল্ড কিনেন অ্যাকাউন্ট থেকে আমি সর্বনিম্ন কত পরিমাণ গোল্ড বিক্রি করতে পারি?",
    body: "আপনার গোল্ড ব্যালেন্স থেকে আপনি যে কোনো পরিমাণের গোল্ড বিক্রি করতে পারেন (ব্যাংক /মোবাইল চার্জ প্রযোজ্য এবং ব্যাংকিং নির্দেশিকা সাপেক্ষে)।"
  },
  {
    title: "আমি গোল্ড বিক্রি করেছি কিন্তু টাকা এখনো আমার ব্যাংক অ্যাকাউন্ট/মোবাইল ওয়ালেটে আসেনি, আমার কী করা উচিত?",
    body: "আপনি যদি আপনার নির্ধারিত ব্যাংক অ্যাকাউন্ট/মোবাইল ওয়ালেটে নির্ধারিত ৩ কার্যদিবসের মধ্যে অর্থ ফেরত/বিক্রীত গোল্ডের পরিমাণ না পান, তাহলে আপনাকে অবিলম্বে 09610964653 কল করে কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।"
  },
  {
    title: "আমি কি আমার নিজস্ব গোল্ড, গোল্ড কিনেন অ্যাপে বিক্রয় করতে পারবো?",
    body: "দুঃখিত, না। আপনি শুধুমাত্র গোল্ড কিনেন অ্যাপ থেকে ক্রয়কৃত গোল্ড, পুনরায় অ্যাপের মাধ্যমে বিক্রয় করতে পারবেন।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপে গোল্ড কেনার কি কোনো সময়সীমা আছে?",
    body: "জি। আপনি প্রতিদিন সকাল ১০টা থেকে রাত ১১:৫৯ পর্যন্ত গোল্ড কিনেন অ্যাপ থেকে গোল্ড কিনতে পারবেন।"
  },
  //MY ACCOUNT//
  {
    title: "আমার মোবাইলে ইতিমধ্যে একটি গোল্ড কিনেন অ্যাকাউন্ট আছে। আমি কীভাবে এটি ওয়েবসাইট-এ অ্যাক্সেস করব?",
    body: "বর্তমানে আমরা ওয়েব পোর্টালের মাধ্যমে আমাদের কোনো লেনদেন সংক্রান্ত পরিষেবা অফার করছি না।"
  },
  {
    title: "আমি কি গোল্ড কিনেনের জন্য একটি যৌথ অ্যাকাউন্ট রাখতে পারি?",
    body: "না, গোল্ড কিনেন অ্যাপের জন্য আপনার যৌথ অ্যাকাউন্ট থাকা সম্ভব নয় ।"
  },
  {
    title: "আমি কিভাবে আমার ফোন নম্বর আপডেট করব?",
    body: "দুঃখিত। আপনি গোল্ড কেনেন অ্যাপের সাথে যুক্ত আপনার ফোন নম্বর আপডেট করতে পারবেন না"
  },
  {
    title: "আমি কীভাবে আমার ব্যাংক অ্যাকাউন্টকে গোল্ড কিনেন অ্যাপের সাথে সংযুক্ত করব?",
    body: "আপনি আপনার গোল্ড কিনেন অ্যাপের সেটিংস অপশনে ট্যাপ করুন  সেটিংস > পেমেন্ট তথ্য – ব্যাংক অপশন থেকে আপনার ব্যাংক অ্যাকাউন্টটি  সংযোগ করতে পারবেন। আপনার ব্যাংক অ্যাকাউন্ট সেট আপ করার সময় আপনাকে অ্যাকাউন্টের নাম, অ্যাকাউন্ট নম্বর, ব্যাংকের নাম, জেলা ও শাখার নাম প্রদান করতে হবে।"
  },
  {
    title: "যদি আমি মারা যাই আমার গোল্ড কিনেন অ্যাকাউন্টের কী হবে?",
    body: "কোনো গ্রাহকের হঠাৎ মৃত্যুতে নির্ধারিত অ্যাকাউন্ট হস্তান্তর প্রক্রিয়া অনুসরণ করে, অ্যাকাউন্ট ধারকের আইনগত উত্তরাধিকারী অ্যাকাউন্টধারীর অ্যাকাউন্ট দাবি করতে পারেন। এক্ষেত্রে তাদের 09610964653 কল করে গোল্ড কিনেন কাস্টমার কেয়ারের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে। বাংলাদেশ সরকার কর্তৃক নির্ধারিত আইন ও প্রবিধান অনুযায়ী, নমিনী শুধুমাত্র গোল্ড কিনেন অ্যাকাউন্টের তত্ত্বাবধায়ক হিসাবে নিবন্ধিত হবেন, মৃত অ্যাকাউন্টধারীর উত্তরাধিকারী নয়।"
  },
  {
    title: "আমি কিভাবে আমার গোল্ড কিনেন অ্যাকাউন্ট স্টেটমেন্ট পেতে পারি?",
    body: "আপনি লেনদেনের ইতিহাস জানার জন্য গোল্ড কিনেন অ্যাপের হোমপেইজে রেকর্ডস অপশনটি দেখতে পারেন।"
  },
  {
    title: "আমি আমার ব্যাংক অ্যাকাউন্টের বিবরণ ইনপুট করতে পারছি না, আমার কী করা উচিত?",
    body: "ব্যাংক অ্যাকাউন্ট যাচাইকরণে বিলম্বের জন্য, আপনাকে 09610964653 কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।"
  },
  {
    title: "রেফার কোড কি?",
    body: "রেফার কোড হল একটি অনন্য নম্বর যা গোল্ড কিনেনের প্রতিটি ব্যবহারকারীকে সনাক্ত করতে ব্যবহৃত হয়। এই রেফার কোডটি গ্রাহক এবং তার রেফারেল (উল্লেখিত গ্রাহক) গোল্ড কিনেনের রেফারাল রিওয়ার্ড ট্র্যাক এবং বিতরণ করতে ব্যবহৃত হয়।"
  },
  //PROMO CODE, REFERRAL & REWARDS //
  {
    title: "প্রোমো কোড কি?",
    body: "প্রোমো কোড হল একটি সংজ্ঞায়িত বাক্যাংশ এবং সংখ্যা যা গোল্ড কিনেন দ্বারা নির্ধারিত করা প্রচারমূলক অফারগুলি উপভোগ করার জন্য ব্যবহার করা যাবে।"
  },
  {
    title: "আমি কিভাবে আমার বন্ধুদের রেফার করতে পারি?",
    body: "আপনার গোল্ড কিনেন অ্যাপের থেকে \"রেফার করুন\" অপশান সিলেক্ট করে আপনার একজন বন্ধুকে রেফার করতে পারেন অথবা আপনি যাকে রেফার করতে চান তার সাথে আপনার ইউনিক রেফার কোড শেয়ার করতে পারেন।"
  },
  {
    title: "রেফার করার পুরস্কার/রিওয়ার্ড কি?",
    body: "একজন গ্রাহককে সফলভাবে রেফার করার পরে রেফারকৃত এবং রেফেরকারী উভয় ব্যক্তি তখন রেফারাল বোনাসের জন্য যোগ্য, যদিও এই বিষয়টি সময়ের সাপেক্ষে পরিবর্তনশীল।"
  },
  {
    title: "রেফারেলের মাধ্যমে আমি আমার রিওয়ার্ড পেয়েছি কিনা তা আমি কীভাবে দেখতে পারি?",
    body: "আপনার গোল্ড কিনেন অ্যাপের ইনবক্সে রেফেরালের মাধ্যমে আপনি যেই রিওয়ার্ড পাবেন তার নোটিফিকেশন পাঠানো হবে।"
  },
  {
    title: "আমি আমার রিওয়ার্ড পাইনি কেন?",
    body: "আপনি যদি প্রোমো/রেফার কোড প্রয়োগ করার পরে নির্ধারিত সময়ের মধ্যে আপনার রিওয়ার্ড না পেয়ে থাকেন, তাহলে অনুগ্রহ করে গোল্ড কিনেন কাস্টমার কেয়ার নম্বর 09610964653 এ যোগাযোগ করুন।"
  },
  {
    title: "আমার প্রমো কোড/রেফার কোড কাজ করেনি, কেন?",
    body: "আপনি আপনার প্রমো /রেফার কোড প্রয়োগ করার পরে যদি আপনি একটি ত্রুটি বার্তা (এরর মেসেজ) দেখতে পান, তার কারণ হতে পারে:\n1) প্রমো কোড মেয়াদউত্তীর্ণ হয়ে গিয়েছে।\n2) প্রমো /রেফার কোড ব্যবহার করার মানদণ্ড পূরণ হচ্ছে না।\n3) প্রমো /রেফার কোডটি অবৈধ।\nএছাড়া অন্য কোন কারণ হলে অনুগ্রহ করে 09610964653 নম্বরে গোল্ড কিনেন কাস্টমার কেয়ারের সাথে যোগাযোগ করুন।"
  },
  {
    title: "গোল্ড কিনেন অ্যাপ ব্যবহার করার পরে আমার রিওয়ার্ড পেতে কতক্ষণ সময় লাগবে?",
    body: "রেফারেল শর্ত পূরণের পরে, আপনি প্রদত্ত মাসের শেষের মধ্যে রিওয়ার্ড পাবেন।"
  },
  {
    title: "আমি কি অন্য গোল্ড কিনেন প্রোমো কোডের সাথে একযোগে আরো একটি গোল্ড কিনেন প্রোমো কোড ব্যবহার করতে পারি?",
    body: "না, আপনি একবারে শুধুমাত্র একটি কোড ব্যবহার করতে পারেন।"
  }
  ,
  //PIN, PASSWORDS//
  {
    title: "লেনদেনের সময়, কেন আমার কাছে একটি ওটিপি OTP কোড চাওয়া হয়েছে?",
    body: "অতিরিক্ত নিরাপত্তা ব্যবস্থা হিসেবে লেনদেনের সময় আপনার কাছে একটি প্রমাণ হিসাবে ওটিপি (OTP) কোড চাওয়া হয়েছে।"
  },
  {
    title: "আমি কিভাবে ২-ফ্যাক্টর অথেনটিকেশন/প্রমাণীকরণ সেট আপ করব?",
    body: "বর্তমানে আমরা অ্যাপটি ব্যবহার করার জন্য শুধুমাত্র পিন নম্বর ব্যবহার করছি। ২-ফ্যাক্টর অথেনটিকেশন/প্রমাণীকরণ, হল একটি অতিরিক্ত নিরাপত্তা ব্যবস্থা যা শুধুমাত্র নিবন্ধন প্রক্রিয়ায় এবং একটি নতুন ডিভাইস থেকে লগ ইন করার সময় ব্যবহৃত হয়।"
  },
  {
    title: "আমি যদি আমার পিন ভুলে যাই তাহলে কি হবে?",
    body: "আপনাকে তখন গোল্ড কিনেন কাস্টমার কেয়ারের সাথে যোগাযোগ করে, পিন পুনঃস্থাপন প্রক্রিয়া শুরু করতে পারেন ।"
  },
  {
    title: "আমি যদি আমার পিন পরিবর্তন করতে চাই?",
    body: "আপনি গোল্ড কিনেন অ্যাপে হোমস্ক্রিন > সেটিংস > পিন রিসেট অপশন থেকে আপনার পিন রিসেট করতে পারেন। বিস্তারিত আরো তথ্যের জন্য আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করুন 09610964653।"
  },
  {
    title: "আমি ভুল পিন লিখলে কি হবে?",
    body: "লগইন করার সময় আপনার পিন প্রবেশের ৫টি প্রচেষ্টা করার সুযোগ থাকবে। আপনি যদি ৫ বারের বেশি ভুল পিন (PIN) লিখে থাকেন, তাহলে আপনার (PIN) নিষ্ক্রিয় করে দেয়া হবে। আপনার অ্যাকাউন্ট পুনরুদ্ধার করার জন্য আপনাকে 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন ।"
  },
  {
    title: "আমি কিভাবে আমার পিন সেটআপ করতে পারি?",
    body: "রেজিস্ট্রেশনের সময়, আপনাকে ওটিপির মাধ্যমে ফোন নম্বর যাচাইকরণের পরে আপনার পিন সেট এবং নিশ্চিত করতে বলা হবে। আপনি গোল্ড কিনেন অ্যাপে হোমস্ক্রিন > সেটিংস > পিন রিসেট অপশন থেকে আপনার পিন রিসেট করতে পারেন।"
  },
  {
    title: "আমি কিভাবে অ্যাপে ভাষা পরিবর্তন করব?",
    body: "আপনি অ্যাপে সেটিংস বাটন ট্যাপ করুন; সেটিংস>ভাষা Toggle, স্ক্রিনে উপরে ডানে (বাংলা/ইংরেজি) থেকে ইংরেজি এবং বাংলার মধ্যে ভাষা পরিবর্তন করতে পারেন।"
  },
  {
    title: "আমি আমার মোবাইল ফোন হারিয়ে ফেলেছি, এখন আমি কি করব?",
    body: "যদি এই ধরনের কোনো দুর্ভাগ্যজনক ঘটনা ঘটে, অনুগ্রহ করে অবিলম্বে আমাদের কাস্টমার সার্ভিস টিমের সাথে যোগাযোগ করুন। 09610964653- এ কল করে আপনার গোল্ড কিনেন অ্যাকাউন্টটিকে \"লক\" করার অনুরোধ করুন ৷"
  },
  {
    title: "আমি আমার মোবাইল ফোন পরিবর্তন করেছি, কিভাবে আমি আমার নতুন মোবাইল ডিভাইস থেকে আমার গোল্ড কিনেন অ্যাকাউন্ট অ্যাক্সেস করতে পারি?",
    body: "আপনার নতুন মোবাইল ফোনে গোল্ড কিনেন অ্যাপ ডাউনলোড করুন এবং আপনার অ্যাকাউন্ট পুনরুদ্ধার করুন। বিস্তারিত আরো তথ্যের জন্য আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করুন 09610964653।"
  },
  {
    title: "আমি গোল্ড কিনেন অ্যাপে আমার প্রোফাইল পূর্ণ করার চেষ্টা করছি, কিন্তু আমি কিছু সমস্যার সম্মুখীন হচ্ছি, আমার কী করা উচিত?",
    body: "আপনি যদি আপনার প্রোফাইল সম্পূর্ণ/আপডেট করতে কোনো সমস্যার সম্মুখীন হন, তাহলে আপনি 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন ।"
  },
  {
    title: "আমি কিভাবে গোল্ড কিনেনের সাথে নিবন্ধিত আমার ইমেল ঠিকানা পরিবর্তন করব?",
    body: "গোল্ড কিনেন অ্যাপের প্রোফাইল সেকশন থেকে আপনার ইমেল ঠিকানা পরিবর্তন করতে পারেন।"
  },
  {
    title: "কেন আমার অ্যাকাউন্ট লক করা হয়েছে?",
    body: "আপনার অ্যাকাউন্ট লক হওয়ার একাধিক কারণ থাকতে পারে। বিস্তারিত জানতে, আপনাকে 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে ।"
  },
  //AUTO GOLD SAVE //
  {
    title: "অটো গোল্ড সেভ কি?",
    body: "গোল্ড কিনেন অ্যাপের অটো গোল্ড সেভ প্ল্যান হল একটি বৈশিষ্ট্য যা গোল্ড কিনেন অ্যাপ ব্যবহারকারীদের স্বয়ংক্রিয়ভাবে ২২ ক্যারাট হলমার্ক প্রত্যয়িত গোল্ড ক্রয় এবং সঞ্চয় করার সুবিধা করে দিচ্ছে। ৩, ৬ ও ১২ মাসের মেয়াদী এই সঞ্চয় প্রকল্পের সাথে ব্যবহারকারীরা, বিকাশ পেমেন্ট পদ্ধতির মাধ্যমে মাসিক ১০০০ টাকা থেকে শুরু করে ৫০,০০০ টাকা পর্যন্ত সঞ্চয় করতে পারবেন।"
  },
  {
    title: "আমি কিভাবে অটো গোল্ড সেভ প্ল্যান শুরু করবেন?",
    body: "আপনার গোল্ড কিনেন অ্যাকাউন্টে লগ ইন করুন, হোম স্ক্রিনে অটো গোল্ড সেভ বাটনে ট্যাপ করুন, প্ল্যানের শিরোনাম লিখুন, মেয়াদ নির্বাচন করুন, তথ্য যাচাই করুন, পিন প্রবেশ করুন এবং পেমেন্ট করুন বাটনে ট্যাপ করে আপনার অটো গোল্ড সেভ যাত্রা শুরু করুন।"
  },
  {
    title: "আমি অটো গোল্ড সেভ কিভাবে কাজ করে?",
    body: "গোল্ড কিনেন অ্যাপ, অটো গোল্ড সেভ প্ল্যান ব্যবহারকারীদের একটি মাসিক গোল্ড সেভিং স্কিম সেট আপ করতে সাহায্য করে; যেখানে ব্যবহারকারীরা একটি নির্দিষ্ট সময়ের জন্য (৩, ৬, ১২ মাস) স্বয়ংক্রিয় বিকাশ পেমেন্টের মাধ্যমে মাসিক ১০০০ টাকা থেকে শুরু করে অ্যাপের মাধ্যমে গোল্ড ক্রয় এবং সঞ্চয় করতে পারবেন।"
  },
  {
    title: "অটো গোল্ড সেভ-এর মেয়াদকাল কতদিনের?",
    body: "গ্রাহক ৩, ৬ এবং ১২ মাসের মেয়াদি অটো গোল্ড সেভ প্ল্যান সক্রিয় করতে পারবেন ৷"
  },
  {
    title: "অটো গোল্ড সেভ প্ল্যান শুরু করার সর্বনিম্ন পরিমাণ কত?",
    body: "অটো গোল্ড সেভ প্ল্যান শুরু করার জন্য সর্বনিম্ন পরিমাণ হল ১০০০ টাকা।"
  },
  {
    title: "অটো গোল্ড সেভ শুরু করতে সর্বোচ্চ পরিমাণ কত?",
    body: "অটো গোল্ড সেভ প্ল্যান শুরু করার সর্বোচ্চ পরিমাণ হল ৫০,০০০ টাকা।"
  },
  {
    title: "অটো গোল্ড সেভ-এর জন্য অর্থপ্রদানের পদ্ধতিগুলি কী কী?",
    body: "বর্তমানে গোল্ড কিনেন অ্যাপ ব্যবহারকারীরা বিকাশ পেমেন্ট পদ্ধতি ব্যবহার করে তাদের অটো গোল্ড সেভ প্ল্যান শুরু করতে পারেন।"
  },
  {
    title: "একসাথে কতগুলি অটো গোল্ড সেভ পরিকল্পনা সক্রিয় হতে পারে?",
    body: "ব্যবহারকারীদের একসাথে সর্বোচ্চ ৫টি অটো গোল্ড সেভ প্ল্যান সক্রিয় থাকতে পারে।"
  },
  {
    title: "আমি যদি অটো গোল্ড সেভ পেমেন্ট মিস করি তাহলে কি হবে?",
    body: "নির্ধারিত তারিখে পেমেন্ট ব্যর্থ হলে, নির্বাচিত পেমেন্ট গেটওয়ের (বিকাশ) নীতি অনুযায়ী পুনরায় পেমেন্ট করার চেষ্টা করা হবে। পেমেন্ট গেটওয়ের মেয়াদের মধ্যে যদি সমস্ত প্রচেষ্টা ব্যর্থ হয় তবে উল্লিখিত মাসের জন্য ব্যবহারকারীর অর্থপ্রদান ব্যর্থ বলে বিবেচিত হবে। তবে অটো গোল্ড সেভ প্ল্যানটি নির্ধারিত সময়ের জন্য পরবর্তী মাসে অব্যাহত থাকবে।"
  },
  {
    title: "আমি কিভাবে অটো গোল্ড সেভ প্ল্যান বাতিল করবেন?",
    body: "আপনার গোল্ড কিনেন অ্যাকাউন্টে লগ ইন করুন, হোম স্ক্রিনে অটো গোল্ড সেভ বাটনে ট্যাপ করুন, আপনি যে প্ল্যানটি বাতিল করতে চান তার 'বিস্তারিত' বাটনে ট্যাপ করুন, স্ক্রিনের নীচে 'অটো গোল্ড সেভ প্ল্যান বাতিল করুন' বাটনে ট্যাপ করুন।"
  },
  {
    title: "অটো গোল্ড সেভ প্ল্যানের অধীনে গোল্ড ক্রয় করার সময় কি ভ্যাট কর্তন করা হবে?",
    body: "হ্যাঁ। সরকারি নীতিমালা অনুযায়ী প্রতি মাসের গোল্ড ক্রয়ে ৫% ভ্যাট কর্তন করা হবে।"
  },
  {
    title: "আমি অটো গোল্ড সেভ প্ল্যান চলমান থাকাকালীন কি আমি প্ল্যানের মাধ্যমে সঞ্চিত গোল্ড উত্তোলন বা বিক্রয় করতে পারি?",
    body: "হ্যাঁ। চলমান অটো গোল্ড সেভ প্ল্যান থাকা সত্ত্বেও গ্রাহক যেকোন সময় তাদের ভল্টে সঞ্চিত গোল্ড উত্তোলন বা বিক্রয় করতে পারবেন।"
  },
  {
    title: "অটো গোল্ড সেভ প্ল্যান ইতিমধ্যে শুরু হয়ে গেলে কি মেয়াদ পরিবর্তন করা সম্ভব?",
    body: "না। অটো গোল্ড সেভ প্ল্যান একবার ইতিমধ্যে শুরু হওয়ার পরে পরিবর্তন করা যাবে না। ব্যবহারকারীরা প্রয়োজনে বিদ্যমান প্ল্যান বাতিল করতে পারেন।"
  }
]


// export const moreFaqDataBn: IMoreFaqData[] = [
//   {
//   body: "গোল্ড কিনেন ২২ক্যারাটের হলমার্ক প্রত্যয়িত গোল্ড কয়েন এবং গোল্ড বার ব্যবহার করে।",
//   title: "  গোল্ড কিনেন-এ কি ধরনের গোল্ড ব্যবহৃত হয়?"
// }, {
//   body: "গোল্ড কিনেন, ৯১.৬% খাঁটি, ২২ ক্যারেট গোল্ড সরবরাহ করে।",
//   title: "  গোল্ড কিনেন দ্বারা অফারকৃত গোল্ডের বিশুদ্ধতা কত?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপ অ্যান্ড্রয়েড (ভার্সন 7 এবং ঊর্ধ্বে ) -এ  উপলব্ধ। অ্যান্ড্রয়েডের জন্য: আপনার অ্যান্ড্রয়েড স্মার্টফোনে গুগল প্লে স্টোর  খুলুন > 'Gold Kinen' সার্চ করুন > 'ইনস্টল করুন",
//   title: "  আমি কিভাবে গোল্ড কিনেন অ্যাপ ডাউনলোড করব?"
// }, {
//   body: "বর্তমানে আমরা ওয়েব পোর্টালের মাধ্যমে আমাদের কোনো লেনদেন সংক্রান্ত পরিষেবা অফার করছি না।",
//   title: "  আমার মোবাইলে ইতিমধ্যে একটি গোল্ড কিনেন অ্যাকাউন্ট আছে। আমি কীভাবে এটি ওয়েবসাইট-এ অ্যাক্সেস করব?"
// }, {
//   body: "KYC মানে আপনার গ্রাহককে জানুন । কেওয়াইসি বা কেওয়াইসি চেক হল বাধ্যতামূলক একটি প্রক্রিয়া যা অ্যাকাউন্ট খোলার সময় এবং পর্যায়ক্রমে সময়ের সাথে সাথে ক্লায়েন্টের পরিচয় সনাক্তকরণ এবং যাচাই করে । আপনার গোল্ড এর নিরাপত্তা সবসময়ই আমাদের সর্বোচ্চ অগ্রাধিকার, এবং আমরা এই দিকগুলিকে উন্নত করার জন্য ক্রমাগত কাজ করছি. আপনার অ্যাকাউন্টের অপব্যবহার বা আপনার গোল্ড এর  অননুমোদিত অ্যাক্সেস রোধ করতে আমরা গোল্ড কিনেন টিম কাজ করছি । এটি আপনার জন্য আরও নিরাপদ অভিজ্ঞতা নিশ্চিত করবে, পাশাপাশি আপনাকে সোনা কেনা, বিক্রি, সংগ্রহ বা উপহার দেওয়ার অনুমতি দেবে।",
//   title: "  KYC কি? কেন KYC প্রয়োজন?"
// }, {
//   body: "না, গোল্ড কিনেন অ্যাপে নমিনী নিয়োগ করা বাধ্যতামূলক নয়৷",
//   title: "  গোল্ড কিনেন অ্যাপের জন্য নমিনী কি বাধ্যতামূলক?"
// }, {
//   body: "আপনার গোল্ড কিনেন অ্যাকাউন্ট সেট আপ হবে একদম বিনামূল্যে। আপনার লেনদেনের সাথে সম্পর্কিত যে সব ফি রয়েছে তার বর্ণনা লেনদেনের সময়, অ্যাপে বিস্তারিত ভাবে বলে দেয়া আছে । এছাড়াও আপনি আমাদের চার্জের সর্বশেষ আপডেট জানতে ক্লিক করুন এই লিংকে",
//   title: "  গোল্ড কিনেন অ্যাপে একটি অ্যাকাউন্ট খুলতে কত খরচ হয়?"
// }, {
//   body: "না। গোল্ড কিনেন বর্তমানে শুধুমাত্র মোবাইল ফোন (Android / IOS) অ্যাপ হিসেবে কাজ করে ।",
//   title: "  আমি কি মোবাইল ফোন ছাড়া গোল্ড কিনেন অ্যাকাউন্ট খুলতে পারি? / এই অ্যাপটা  কি আমার ডেস্কটপ থেকে চালানো যাবে?"
// }, {
//   body: "হ্যাঁ। অনুগ্রহ করে আমাদের <a href='/${locale}/scheduled-charges'><u>চার্জ সমূহের সর্বশেষ তালিকা</u></a> দেখে নিন ।",
//   title: "  গোল্ড কিনেন অ্যাপে প্রদত্ত পরিষেবাগুলির জন্য কি কোন চার্জ আছে?"
// }, {
//   body: "একটি গোল্ড কিনেন অ্যাকাউন্ট খুলতে আপনার সর্বনিম্ন বয়স হতে হবে ১৮ বছর বা তার বেশি এবং আপনাকে বাংলাদেশে বসবাস করতে হবে।",
//   title: "  একটি গোল্ড কিনেন অ্যাকাউন্ট খোলার ন্যূনতম মানদণ্ড কী?"
// }, {
//   body: "অতিরিক্ত নিরাপত্তা ব্যবস্থা হিসেবে লেনদেনের সময় আপনাকে একটি প্রমাণ হিসাবে ওটিপি (OTP) কোড চাওয়া হয়েছে।",
//   title: "  লেনদেনের সময়, কেন আমার কাছে একটি ওটিপি OTP কোড চাওয়া হয়েছে?"
// }, {
//   body: "না, গোল্ড কিনেন অ্যাপের জন্য আপনার যৌথ অ্যাকাউন্ট থাকা প্রযোজ্য নয়",
//   title: "  আমি কি গোল্ড কিনেনের জন্য একটি যৌথ অ্যাকাউন্ট রাখতে পারি?"
// }, {
//   body: "অনুগ্রহ করে মনে রাখবেন যে শুধুমাত্র গোল্ড কিনেন অ্যাপ ব্যবহারকারীরা 09610964653 নম্বরে ফোন করে কাস্টমার কেয়ার টিমের সাথে যোগাযোগের মাধ্যমে তাদের ফোন নম্বর আপডেট করতে পারবেন। ",
//   title: "  আমি কিভাবে আমার ফোন নম্বর আপডেট করব?"
// }, {
//   body: "আপনি অ্যাপে সেটিংস বাটন ট্যাপ করুন; সেটিংস >ভাষা Toggle (বাংলা/ইংরেজি) থেকে ইংরেজি এবং বাংলার মধ্যে ভাষা পরিবর্তন করতে পারেন।",
//   title: "  আমি কিভাবে অ্যাপে ভাষা পরিবর্তন করব?"
// }, {
//   body: "বর্তমানে আমরা অ্যাপটি ব্যবহার করার জন্য শুধুমাত্র পিন নম্বর ব্যবহার করছি। ২-ফ্যাক্টর অথেনটিকেশন/প্রমাণীকরণ, হল একটি অতিরিক্ত নিরাপত্তা ব্যবস্থা যা শুধুমাত্র নিবন্ধন প্রক্রিয়ায় এবং একটি নতুন ডিভাইস থেকে লগ ইন করার সময় ব্যবহৃত হয়।",
//   title: "  আমি কিভাবে ২-ফ্যাক্টর অথেনটিকেশন/প্রমাণীকরণ সেট আপ করব?"
// }, {
//   body: "আপনি আপনার গোল্ড কিনেন অ্যাপের সেটিংস অপশনে ট্যাপ করুন  সেটিংস  > প্রোফাইল  > পেমেন্ট থেকে আপনার ব্যাংক অ্যাকাউন্টটি  সংযোগ করতে পারবেন। আপনার ব্যাংক অ্যাকাউন্ট সেট আপ করার সময় আপনাকে অ্যাকাউন্টের নাম, অ্যাকাউন্ট নম্বর, ব্যাংকের নাম, জেলা ও শাখার নাম প্রদান করতে হবে।",
//   title: "  আমি কীভাবে আমার ব্যাংক অ্যাকাউন্টকে গোল্ড কিনেন অ্যাপের সাথে সংযুক্ত করব?"
// }, {
//   body: "'গোল্ড ক্রয়' অপশনটি সিলেক্ট করার মাধ্যমে, আপনি যে পরিমাণ গোল্ড কিনতে চান তা প্রবেশ করে, তারপর মোবাইল ওয়ালেট বা ডেবিট/ক্রেডিট কার্ডের মাধ্যমে আপনার অর্থপ্রদান নিশ্চিত ",
//   title: "  গোল্ড কিনেন অ্যাপ থেকে কিভাবে গোল্ড কিনবেন?"
// }, {
//   body: "আপনি একদিনে আনলিমিটেড/অগণিত বার গোল্ড কিনতে পারবেন।",
//   title: "  আমি দিনে কতবার গোল্ড কিনতে পারি?"
// }, {
//   body: "আপনি একক লেনদেনে ৩০০,০০০ টাকা পর্যন্ত গোল্ড কিনতে পারবেন।",
//   title: "  একক লেনদেনে আমি আমার গোল্ড কিনেন অ্যাকাউন্ট থেকে কতটুক গোল্ড  কিনতে পারি?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপে গোল্ডের ক্রয়মূল্য বাজুস-এর নিয়মিত ঘোষিত মূল্যে গোল্ড ক্রয় করা সম্ভব। আজকের গোল্ড বাজারমূল্যের সম্পর্কে আরও তথ্যের জন্য অনুগ্রহ করে এই লিংকে ক্লিক করুন  https://www.bajus.org/",
//   title: "  গোল্ড কিনেন অ্যাপে গোল্ডের ক্রয় মূল্য কত?"
// }, {
//   body: "গোল্ড ক্রয়, লেনদেন হওয়ার অবিলম্বে প্রক্রিয়াধিন হয়ে যায়।",
//   title: "  গোল্ড ক্রয়ের জন্য প্রক্রিয়াকরণের সর্বনিম্ন সময়সীমা কী?"
// }, {
//   body: "সকল গোল্ড কিনেন অ্যাপ পরিষেবা সমূহ পেতে আপনাকে আপনার ব্যাংক অ্যাকাউন্ট সংযোগ করতে হবে না। ব্যাংক অ্যাকাউন্ট/মোবাইল ওয়ালেট তথ্য শুধুমাত্র গোল্ড বিক্রয়ের সময় প্রয়োজন।",
//   title: "  গোল্ড কিনেন অ্যাপ পরিষেবাগুলি পেতে আমাকে কি আমার ব্যাংক অ্যাকাউন্ট সংযুক্ত করতে হবে?"
// }, {
//   body: "না। বর্তমানে আমরা যে দেশে অবস্থিত শুধুমাত্র সেই দেশের মুদ্রায় (অর্থাৎ টাকায়) আপনি গোল্ড কিনতে পারবেন।",
//   title: "  আমি কি টাকা  ছাড়া অন্য কোনো মুদ্রায় গোল্ড কিনতে পারি?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপে গোল্ড ক্রয় করার সর্বনিম্ন পরিমাণ ৫০০টাকা।",
//   title: "  সর্বনিম্ন কত টাকায় গোল্ড কিনেন অ্যাপে গোল্ড কেনা সম্ভব ?"
// }, {
//   body: "আপনার গোল্ড কিনেন অ্যাপ থেকে ক্রয়কৃত গোল্ড বাংলাদেশের একটি শীর্ষস্থানীয় স্টোরেজ প্রদানকারী দ্বারা সুরক্ষিত ভাবে সংরক্ষণ করা হয়। এছাড়াও, আপনার ক্রয়কৃত গোল্ড সম্পূর্ণরূপে ",
//   title: "  গোল্ড কিনেন অ্যাপ থেকে ক্রয়কৃত গোল্ড কোথায় জমা/ সংরক্ষিত হয়?"
// }, {
//   body: "আপনি শুধুমাত্র আপনার মোবাইল ওয়ালেট/ব্যাংক অ্যাকাউন্ট/ক্রেডিট কার্ডের প্রাপনীয় ব্যালেন্স যতটুক আছে সেই সমপরিমাণ গোল্ড কিনতে পারবেন। একবার একটি লেনদেন কার্যকর করা হলে তা আর ফেরতযোগ্য নহে।",
//   title: "  গোল্ড কেনার সময় যদি আমি ভুল পরিমাণ লিখি?"
// }, {
//   body: "গোল্ডের বিক্রীত সমপরিমাণ অর্থ, অন্তত ৩ কার্যদিবসের মধ্যে (BEFTN এবং বাংলাদেশ ব্যাংকের নিয়ম অনুসারে) জমা হয়। অতিরিক্ত যেকোনো বিলম্ব পেমেন্ট গেটওয়ে বা আর্থিক প্রতিষ্ঠানের অধীনে বিলম্ব সাপেক্ষ।",
//   title: "  বিক্রিত গোল্ড পরিমাণ অগ্রগমণ করতে কতক্ষণ লাগে?"
// }, {
//   body: "আপনি যদি নির্ধারিত সময়সীমার মধ্যে আপনার মনোনীত মোবাইল ওয়ালেট/ব্যাংক অ্যাকাউন্টে ফেরত/বিক্রীত সমপরিমাণ অর্থ না পেয়ে থাকেন তাহলে আপনাকে 09610964653 এ কল করে কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।  ",
//   title: "  সর্বোচ্চ প্রক্রিয়াকরণের সময়সীমা অতিক্রম হয়ে গেলে আমার বিক্রয়কৃত অর্থ আমার  কাছে জমা না হলে কী হবে?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপ থেকে বিক্রয়কৃত সমপরিমাণ গোল্ডের টাকা আপনি মোবাইল ওয়ালেট এবং ব্যাংকিং চ্যানেলের মাধ্যমে পেতে পারেন।",
//   title: "  গোল্ড কিনেন অ্যাপ থেকে বিক্রয়কৃত সমপরিমাণ গোল্ডের অর্থ আমি কোথায় গ্রহণ করতে পারি?"
// }, {
//   body: "আপনি আপনার অ্যাকাউন্টে জমাকৃত গোল্ড একদিনে অপরিমিত সংখ্যক গোল্ড বিক্রি করতে পারেন।",
//   title: "  আমি দিনে কতবার গোল্ড বিক্রি করতে পারি?"
// }, {
//   body: "আপনি আপনার অ্যাকাউন্ট থেকে উপলব্ধ গোল্ড ব্যালেন্স পরিমাণ সমান গোল্ড বিক্রি করতে পারেন ( ব্যাংক /মোবাইল চার্জ প্রযোজ্য এবং ব্যাংকিং নির্দেশিকা সাপেক্ষে)।",
//   title: "  আমার গোল্ড কিনেন অ্যাকাউন্ট থেকে সর্বোচ্চ বিক্রিযোগ্য গোল্ডের পরিমাণ কত?"
// }, {
//   body: "আপনার গোল্ড ব্যালেন্স থেকে ন্যূনতম ৫০০ টাকা সমপরিমাণ গোল্ড আপনি বিক্রি করতে পারেন  (ব্যাংক /মোবাইল চার্জ প্রযোজ্য এবং  ব্যাংকিং নির্দেশিকা সাপেক্ষে)।",
//   title: "  আমার গোল্ড কিনেন অ্যাকাউন্ট থেকে আমি সর্বনিম্ন কত পরিমাণ গোল্ড বিক্রি করতে পারি?"
// }, {
//   body: "একবার গোল্ড কিনেন অ্যাপে গোল্ড ক্রয়/বিক্রয় লেনদেন সম্পাদিত হলে, তা পরবর্তীতে ফেরতযোগ্য নহে।",
//   title: "  গোল্ড ক্রয় বা বিক্রয়ের সময় যদি আমি ভুল পরিমাণ লিখি?"
// }, {
//   body: "আপনি যদি আপনার নির্ধারিত ব্যাংক অ্যাকাউন্ট/মোবাইল ওয়ালেটে নির্ধারিত ৩ কার্যদিবসের মধ্যে অর্থ ফেরত/বিক্রীত সোনার পরিমাণ না পান, তাহলে আপনাকে অবিলম্বে 09610964653 কল করে কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।",
//   title: "  আমি গোল্ড বিক্রি করেছি কিন্তু টাকা এখনো আমার ব্যাংক অ্যাকাউন্ট/মোবাইল ওয়ালেটে আসেনি, আমার কী করা উচিত?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপে গোল্ড উত্তোলন (কালেক্ট গোল্ড) অপশনের মাধ্যমে আপনার ক্রয়কৃত গোল্ড সংগ্রহ করা সম্ভব।",
//   title: "  কিভাবে আপনার ক্রয়কৃত গোল্ড সংগ্রহ করবেন?"
// }, {
//   body: "আপনার গোল্ড বীমাকৃত হয়ে আমাদের নির্ধারিত ডেলিভারি পার্টনার দ্বারা আপনার নির্ধারিত ঠিকানায় পৌঁছে দেওয়া হবে।",
//   title: "  কিভাবে গোল্ড আমার কাছে ডেলিভারি করা হবে?"
// }, {
//   body: "ডেলিভারি পাওয়ার সময় গ্রাহকদের তাদের জাতীয় পরিচয়পত্র (NID) দেখাতে হবে।",
//   title: "  গোল্ড কয়েন বা গোল্ড বার নেওয়ার সময় গ্রাহককে কি কোনো পরিচয়পত্র বা  প্রমাণ দেখাতে হবে?"
// }, {
//   body: "বর্তমানে একদিনে গোল্ড উত্তোলনের কোনো সীমা নেই।",
//   title: "  একদিনে গোল্ড কালেক্ট/ উত্তোলনের সীমা কত?"
// }, {
//   body: "আপনি প্রোফাইল সেকশন থেকে আপনার ডেলিভারি ঠিকানা পরিবর্তন করতে পারেন. যদিও, অনুরোধকৃত পরিবর্তন অনুমোদন না হওয়া পর্যন্ত আপনার অ্যাকাউন্ট লক থাকবে।",
//   title: "  আমি কি আমার ডেলিভারি ঠিকানা পরিবর্তন করতে পারি?"
// }, {
//   body: "গোল্ড কালেক্টের জন্য ২% পরিষেবা চার্জ প্রযোজ্য। পাশাপাশি, ইনসিওরড ডেলিভারির জন্য চার্জ তালিকা অনুযায়ী ডেলিভারি চার্জ প্রযোজ্য হবে।",
//   title: "  গোল্ড ডেলিভারির জন্য কি কোন চার্জ আছে?"
// }, {
//   body: "দেশের একটি শীর্ষস্থানীয় লজিস্টিক কোম্পানির নিয়োগের মাধ্যমে গোল্ড কিনেন আপনার আমানতের নিরাপদ ডেলিভারি নিশ্চিত করে ৷ আমাদের সব ডেলিভারি সম্পূর্ণভাবে বীমাকৃত।",
//   title: "  গোল্ড কিনেন কীভাবে আমার গোল্ডের নিরাপদ ডেলিভারি নিশ্চিত করে?"
// }, {
//   body: "আপনি ব্যাতিত অন্য কেউ আপনার পক্ষ হয়ে আপনার সংগ্রহযোগ্য গোল্ড রিডিম করতে পারবে না। শুধুমাত্র আপনার অ্যাপে কালেক্ট গোল্ড অপশনের মাধ্যমেই আপনি ফিজিক্যাল গোল্ড রিডিম করতে পারবেন।",
//   title: "  আমি বাদে অন্য কেউ কি আমার পক্ষ থেকে আমার গোল্ড রিডিম/ খালাস করতে পারে?"
// }, {
//   body: "যেহেতু আমাদের সকল ডেলিভারি সম্পূর্ণভাবে বীমাকৃত, দুর্ভাগ্যক্রমে যদি গ্রাহকের গোল্ড হারিয়ে যায়, তাহলে গ্রাহককে প্রয়োজনীয় তদন্তের পরে গোল্ড সমপরিমাণ অর্থ রিফান্ড/ফেরত দেয়া হবে। যদি ঘটনাটি গ্রাহকের অবহেলার কারণে না ঘটে থাকে ।",
//   title: "  ডেলিভারির সময় আমার গোল্ড হারিয়ে গেলে কি হবে?"
// }, {
//   body: "আপনি গোল্ড কিনেন অ্যাপে 'গিফ্ট গোল্ড' অপশনটি নির্বাচন করে কয়েকটি ধাপের মধ্যেই আপনার প্রিয়জনকে গোল্ড উপহার দিতে পারবেন।",
//   title: "  আমি কিভাবে গোল্ড গিফ্ট /উপহার দেব?"
// }, {
//   body: "বর্তমানে একদিনে 'গোল্ড গিফ্ট' করার কোন সীমা নেই।",
//   title: "  আমি একদিনে কত গোল্ড গিফ্ট/উপহার দিতে পারবো তার কি কোনো সীমা আছে?"
// }, {
//   body: "উপহারকৃত গোল্ড সরাসরি প্রাপকের অ্যাকাউন্টে স্থানান্তরিত হয়। তাই উপহারকৃত গোল্ড না রিসিভ করার জন্য বর্তমানে কোনো বিকল্প উপায় নেই।",
//   title: "  কেউ যদি উপহারকৃত গোল্ড রিসিভ না করতে চায়?"
// }, {
//   body: "না, উপহারের কোনো মেয়াদ শেষ হওয়ার তারিখ নেই।",
//   title: "  উপহারের জন্য কোন মেয়াদ শেষ হওয়ার তারিখ আছে কি?"
// }, {
//   body: "আপনার গোল্ড কিনেন অ্যাপের ইনবক্সে যেকোন প্রেরিত/প্রাপ্ত উপহার নোটিফিকেশনের মাধ্যমে জানানো হবে । এছাড়া আপনার গোল্ড কিনেন অ্যাপের ইতিহাস অপশনে উপহার ট্যাব থেকে গোল্ড গিফ্টের বিস্তারিত তথ্য জানতে পারেন ।",
//   title: "  গোল্ড উপহার দেয়ার পরে কিভাবে আমি উপহারকৃত গোল্ডের অবস্থান দেখবো?"
// }, {
//   body: "প্রেরিত/প্রাপ্ত যেকোন উপহার অ্যাপ নোটিফিকেশনের মাধ্যমে জানানো হবে। এছাড়াও আপনি গোল্ড কিনেন অ্যাপের ইতিহাস অপশনে উপহার ট্যাব থেকে 'গোল্ড গিফ্ট-এর' বিবরণ দেখতে পারেন।",
//   title: "  প্রেরিত এবং প্রাপ্ত গোল্ড ট্র্যাক করার কোন উপায় আছে কি?"
// }, {
//   body: "না। বর্তমানে আপনি এমন কাউকে গোল্ড উপহার দিতে পারবেন না যার গোল্ড কিনেন অ্যাকাউন্ট নেই।",
//   title: "  আমি কি এমন একজন ব্যক্তি/প্রাপককে গোল্ড উপহার দিতে পারি যার গোল্ড কিনেন অ্যাকাউন্ট নেই?"
// }, {
//   body: "বর্তমানে গিফ্ট গোল্ডের উপরে কোনো চার্জ নির্ধারিত নেই । গোল্ড কিনেন অ্যাপে উল্লেখিত দিনের বাজার মূল্য অনুযায়ী গোল্ডের রেট/হার প্রয়োগ করা হবে। এই তথ্যটি বাংলাদেশ জুয়েলার্স এসোসিয়েশন (বাজুস)-এর ওয়েবসাইটে সকলের জন্য উন্মুক্ত ভাবে প্রকাশিত।",
//   title: "  গিফ্ট গোল্ডের সময় গোল্ডের রেট/হার কীভাবে গণনা করা হয়?"
// }, {
//   body: "না। আপনি বিদেশে গোল্ড পাঠাতে পারবেন না।",
//   title: "  আমি কি বিদেশে গোল্ড পাঠাতে পারি?"
// }, {
//   body: "যেহেতু সকল 'গিফ্ট গোল্ড' অনুরোধ রিয়েল টাইমে প্রসেস করা হয়, আপনার কাছে অ্যাপে নোটিফিকেশন আসবে যে প্রাপকের অ্যাপে গোল্ড জমা করা হয়েছে। এছাড়াও আপনি গোল্ড কিনেন অ্যাপের ইতিহাস অপশনে গিফট গোল্ড ট্যাব থেকে গিফটেড গোল্ডের বিবরণ দেখতে পারেন।",
//   title: "  আমার পাঠানো গোল্ড কেউ পেয়েছে কিনা আমি কিভাবে বুঝব?"
// }, {
//   body: "যদি আপনার গোল্ড গিফ্টের প্রাপক একজন নিবন্ধিত গোল্ড কিনেন ব্যবহারকারী না হন, তাহলে লেনদেন সফল হবে না।",
//   title: "  আমি একটি নতুন ব্যবহারকারীর কাছে কিছু গোল্ড পাঠিয়েছি, কিন্তু তারা কখনই সাইন আপ করেনি। এখন কি হবে?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপে আপনার দ্বারা সম্পন্ন সকল লেনদেন অপরিবর্তনীয়। আপনার সকল লেনদেনের জন্য আপনি সম্পূর্ণরূপে দায়ী।",
//   title: " ভুল ব্যক্তির কাছে গোল্ড পাঠালে কি হবে?"
// }, {
//   body: "ডেলিভারি পাওয়ার সময় গ্রাহকদের বৈধ জাতীয় পরিচয়পত্র (NID) দেখাতে হবে।",
//   title: "  গোল্ড গ্রহণের সময় প্রাপকের কি কোনো পরিচয় প্রমাণ দেখাতে হবে?"
// }, {
//   body: "আমাদের <a href='/${locale}/scheduled-charges'><u>সর্বশেষ চার্জের তালিকা</u></a> চেক করুন।",
//   title: "  গোল্ড কিনেনের ফি এবং সার্ভিস চার্জ কি কি?"
// }, {
//   body: "বর্তমানে গোল্ড কিনেনে গোল্ড কালেক্ট/সংগ্রহে কোন চার্জ নেই। তবে, ডেলিভারি পার্টনারদের নির্ধারিত চার্জ অনুযায়ী ডেলিভারি ফি প্রযোজ্য হবে, যা গ্রাহক দ্বারা বহন করা হবে।",
//   title: "  গোল্ড কিনেন থেকে গোল্ড কালেক্ট/সংগ্রহ করতে কত খরচ হয়?"
// }, {
//   body: "আমাদের <a href='/${locale}/scheduled-charges'><u>সর্বশেষ চার্জের তালিকা সমূহ</u></a> দেখুন এখানে।",
//   title: "  প্রতিটি পেমেন্ট অপশনের সাথে সম্পর্কিত চার্জ কি কি?"
// }, {
//   body: "আজকের দিনের অফিসিয়াল বাজার মূল্যের উপর ভিত্তি করে, আপনার গোল্ড ব্যালেন্সের এখনকার মূল্য নির্ধারিত হবে, যা গোল্ড কিনেন অ্যাপে দেখানো হয়েছে।",
//   title: "  আমি কিভাবে জানবো আমার গোল্ডের এখনকার মূল্য কত?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপে গোল্ড কেনার সময় আপনি যে সার্ভিস চার্জ প্রদান করেন তার মধ্যে গোল্ড স্টোরেজ চার্জ অন্তর্ভুক্ত থাকে ।",
//   title: "  আপনি কি আমার গোল্ড স্টোরেজের জন্য চার্জ করবেন?"
// }, {
//   body: "আমাদের গোল্ডের দর বাজুস কর্তৃক প্রকাশিত অফিসিয়াল রেট দ্বারা নির্ধারিত হয়। আপনি বাজুস ওয়েবসাইটে যেয়ে এই ব্যাপারে নিশ্চিত হতে পারবেন ।",
//   title: "  আমি কিভাবে জানব যে গোল্ডের প্রদত্ত বাজারের রেট আসল?"
// }, {
//   body: "আপনার গোল্ড বাংলাদেশে অবস্থিত উচ্চস্তরের নিরাপত্তা ভল্টে সুরক্ষিত আছে যা দেশের একটি শীর্ষস্থানীয় নিরাপত্তা সংস্থা দ্বারা পরিচালিত।",
//   title: "  আমার গোল্ড কোথায় জমা আছে?"
// }, {
//   body: "হ্যা, আপনি পারবেন! আপনি যখন আমাদের 'কালেক্ট গোল্ড' পরিষেবাটি ব্যবহার করবেন, তার মাধ্যমে আপনার কাছে সরাসরি ডেলিভারি হয়ে গোল্ড পৌঁছে যাবে ।",
//   title: "  আমি কি আমার গোল্ড দেখতে পারি?"
// }, {
//   body: "আমাদের   'কলেক্ট গোল্ড' পরিষেবাটি ব্যবহার করে আপনি আপনার সুবিধা অনুযায়ী স্থানে বা বাসায় আপনার সংরক্ষিত গোল্ড ডেলিভারি নিতে পারেন।",
//   title: "  আমি কি বাস্তবে আমার গোল্ড উত্তোলন করতে পারি?"
// }, {
//   body: "আপনার গোল্ড বাংলাদেশে অবস্থিত একটি উচ্চতর নিরাপদ ভল্টে সুরক্ষিত রয়েছে যা দেশের একটি শীর্ষ নিরাপত্তা সংস্থার দ্বারা পরিচালিত । অধিকন্তু, দেশের অন্যতম প্রধান বীমা কোম্পানির দ্বারা আপনার গোল্ড সম্পূর্ণরূপে বীমাকৃত।",
//   title: "  আমি কীভাবে জানব যে আমার গোল্ড নিরাপদে আছে?"
// }, {
//   body: "যেহেতু আমাদের সকল ডেলিভারি সম্পূর্ণভাবে বীমাকৃত, দুর্ভাগ্যক্রমে যদি গ্রাহকের গোল্ড হারিয়ে যায়, তাহলে গ্রাহককে প্রয়োজনীয় তদন্তের পরে গোল্ড সমপরিমাণ অর্থ রিফান্ড/ফেরত দেয়া হবে। যদি ঘটনাটি গ্রাহকের অবহেলার কারণে না ঘটে থাকে ।",
//   title: "  ডেলিভারির সময় আমার গোল্ড হারিয়ে গেলে কি হবে?"
// }, {
//   body: "গোল্ড কিনেন আপনাকে সম্পূর্ণরূপে হলমার্ক প্রত্যয়িত ২২ ক্যারেট গোল্ড কয়েন এবং গোল্ড বার সরবরাহ করে। আমাদের সকল সার্টিফিকেশন বাংলা গোল্ড অথবা ঢাকা গোল্ড দ্বারা প্রত্যয়িত।",
//   title: "  আমি কীভাবে জানব যে আমার গোল্ড আসল কি না?"
// }, {
//   body: "হ্যা! আপনার গোল্ড বীমাকৃত । দেশের শীর্ষস্থানীয় বীমা কোম্পানিগুলির মধ্যে একটি দ্বারা আপনার গোল্ড সম্পূর্ণ বীমা করা আছে ।",
//   title: "  আমার গোল্ড কি বীমাকৃত?"
// }, {
//   body: "আমাদের গোল্ড কয়েন এবং গোল্ড বার বাংলা গোল্ড/ঢাকা গোল্ড দ্বারা প্রত্যয়িত।",
//   title: "  গোল্ড কিনন দ্বারা প্রদত্ত গোল্ডের প্রত্যয়নকারী সংস্থাগুলি কোনটি?"
// }, {
//   body: "কোনো গ্রাহকের হঠাৎ মৃত্যুতে নির্ধারিত অ্যাকাউন্ট হস্তান্তর প্রক্রিয়া অনুসরণ করে, অ্যাকাউন্ট ধারকের আইনগত উত্তরাধিকারী অ্যাকাউন্টধারীর অ্যাকাউন্ট দাবি করতে পারেন। এক্ষেত্রে তাদের 09610964653 কল করে গোল্ড কিনেন কাস্টমার কেয়ারের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।",
//   title: "  যদি আমি মারা যাই আমার গোল্ড কিনেন অ্যাকাউন্টের কী হবে?"
// }, {
//   body: "আপনার গোল্ড কিনেন অ্যাপের থেকে 'রেফার করুন' অপশন সিলেক্ট করে আপনার একজন বন্ধুকে রেফার করতে পারেন অথবা আপনি যাকে রেফার করতে চান তার সাথে আপনার ইউনিক রেফার কোড শেয়ার করতে পারেন।",
//   title: "  আমি কিভাবে আমার বন্ধুদের রেফার করতে পারি?"
// }, {
//   body: "একজন গ্রাহককে সফলভাবে রেফার করার পরে রেফারকৃত এবং রেফেরকারী উভয় ব্যক্তি তখন রেফারাল বোনাসের জন্য যোগ্য, যদিও এই বিষয়টি সময়ের সাপেক্ষে পরিবর্তনশীল। ",
//   title: "  রেফার করার পুরস্কার/রিওয়ার্ড কি?"
// }, {
//   body: "আপনার গোল্ড কিনেন অ্যাপের ইনবক্সে রেফেরালের মাধ্যমে আপনি যেই রিওয়ার্ড পাবেন তার নোটিফিকেশন পাঠানো হবে।",
//   title: "  রেফারেলের মাধ্যমে আমি আমার রিওয়ার্ড পেয়েছি কিনা তা আমি কীভাবে দেখতে পারি?"
// }, {
//   body: "আপনি যদি প্রোমো/রেফার কোড প্রয়োগ করার পরে নির্ধারিত সময়ের মধ্যে আপনার রিওয়ার্ড না পেয়ে থাকেন, তাহলে অনুগ্রহ করে গোল্ড কিনেন কাস্টমার কেয়ার নম্বর 09610964653 এ যোগাযোগ করুন।",
//   title: "  আমি আমার রিওয়ার্ড পাইনি কেন?"
// }, {
//   body: "আপনি আপনার প্রমো /রেফার কোড প্রয়োগ করার পরে যদি আপনি একটি ত্রুটি বার্তা (এরর মেসেজ) দেখতে পান, তার কারণ হতে পারে: 1) প্রমো কোড মেয়াদউত্তীর্ণ হয়ে গিয়েছে।  2) প্রমো /রেফার কোড ব্যবহার করার মানদণ্ড পূরণ হচ্ছে না।  3) প্রমো /রেফার কোডটি অবৈধ। এছাড়া অন্য কোন কারণ হলে অনুগ্রহ করে 09610964653 নম্বরে গোল্ড কিনেন কাস্টমার কেয়ারের সাথে যোগাযোগ করুন।",
//   title: "  আমার প্রমো কোড/রেফার কোড কাজ করেনি, কেন?"
// }, {
//   body: "রেফারেল শর্ত পূরণের পরে, আপনি প্রদত্ত মাসের শেষের মধ্যে রিওয়ার্ড পাবেন।",
//   title: "  গোল্ড কিনেন অ্যাপ ব্যবহার করার পরে আমার রিওয়ার্ড পেতে কতক্ষণ সময় লাগবে?"
// }, {
//   body: "না, আপনি একবারে শুধুমাত্র একটি কোড ব্যবহার করতে পারেন।",
//   title: "  আমি কি অন্য গোল্ড কিনেন প্রোমো কোডের সাথে একযোগে আরো একটি গোল্ড কিনেন প্রোমো কোড ব্যবহার করতে পারি?"
// }, {
//   body: "গ্রাহকের অভিযোগ আমাদের নিবেদিত গ্রাহক পরিষেবা দল (কাস্টমার সার্ভিস টীম) দ্বারা পরিচালিত হয়। আপনি 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন ৷",
//   title: "  গ্রাহকের অভিযোগ পরিচালনা করার দায়িত্ব কার?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপে কোনো প্রকারের সমস্যা হলে 09610964653 কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন।",
//   title: "  গোল্ড কিনেনের কোনো টেকনিক্যাল (প্রযুক্তিগত) সমস্যা রিপোর্ট করব কিভাবে?"
// }, {
//   body: "যদি এই ধরনের কোনো দুর্ভাগ্যজনক ঘটনা ঘটে, অনুগ্রহ করে অবিলম্বে আমাদের কাস্টমার সার্ভিস টিমের সাথে যোগাযোগ করুন। 09610964653- এ কল করে আপনার গোল্ড কিনেন অ্যাকাউন্টটিকে 'লক' করার অনুরোধ করুন ৷",
//   title: "  আমি আমার মোবাইল ফোন হারিয়ে ফেলেছি, এখন আমি কি করব?"
// }, {
//   body: "আপনার নতুন মোবাইল ফোনে গোল্ড কিনেন অ্যাপ ডাউনলোড করুন এবং আপনার অ্যাকাউন্ট পুনরুদ্ধার করুন।",
//   title: "  আমি আমার মোবাইল ফোন পরিবর্তন করেছি, কিভাবে আমি আমার নতুন মোবাইল ডিভাইস থেকে আমার গোল্ড কিনেন অ্যাকাউন্ট অ্যাক্সেস করতে পারি?"
// }, {
//   body: "আপনাকে তখন গোল্ড কিনেন কাস্টমার কেয়ারের সাথে যোগাযোগ করে, পিন পুনঃস্থাপন প্রক্রিয়া শুরু করতে পারেন ।",
//   title: "  আমি যদি আমার পিন ভুলে যাই তাহলে কি হবে?"
// }, {
//   body: "আপনি গোল্ড কিনেন অ্যাপে প্রোফাইল  > সেটিংস  > লগইন অপশন থেকে আপনার পিন রিসেট করতে পারেন।",
//   title: "   আমি যদি আমার পিন পরিবর্তন করতে চাই?"
// }, {
//   body: "লগইন করার সময় আপনার পিন প্রবেশের ৫টি প্রচেষ্টা করার সুযোগ থাকবে। আপনি যদি ৫ বারের বেশি ভুল পিন (PIN) লিখে থাকেন, তাহলে আপনার (PIN) নিষ্ক্রিয় করে দেয়া হবে। আপনার অ্যাকাউন্ট পুনরুদ্ধার করার জন্য আপনাকে 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন ।",
//   title: "  আমি ভুল পিন লিখলে কি হবে?"
// }, {
//   body: "রেজিস্ট্রেশনের সময়, আপনাকে ওটিপির মাধ্যমে ফোন নম্বর যাচাইকরণের পরে আপনার পিন সেট এবং নিশ্চিত করতে বলা হবে। আপনি গোল্ড কিনেন অ্যাপে প্রোফাইল  > সেটিংস  > লগইন অপশন থেকে আপনার পিন রিসেট করতে পারেন।",
//   title: "  আমি কিভাবে আমার পিন সেটআপ করতে পারি?"
// }, {
//   body: "আপনি যদি আপনার প্রোফাইল সম্পূর্ণ/আপডেট করতে কোনো সমস্যার সম্মুখীন হন, তাহলে আপনি 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করতে পারেন ।",
//   title: "  আমি গোল্ড কিনেন অ্যাপে আমার প্রোফাইল পূর্ণ করার চেষ্টা করছি, কিন্তু আমি কিছু সমস্যার সম্মুখীন হচ্ছি, আমার কী করা উচিত?"
// }, {
//   body: "আপনার বর্তমান অ্যাপ সেটিংস সর্বোত্তর কিনা তা নিশ্চিত করতে, আপনার গোল্ড কিনেন অ্যাপের ক্যামেরার অনুমতি দেয়া আছে কিনা তা দেখে নিন । আপনি যদি এখনও সমস্যার সম্মুখীন হন, তাহলে আপনাকে 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে ।",
//   title: "  গোল্ড কিনেন অ্যাপে থাকা অবস্থায় আমি ক্যামেরা ব্যবহার করে আমার শনাক্তকরণ/এনআইডির জন্য ছবি তুলতে পারছি না । আমার কি করা উচিৎ?"
// }, {
//   body: "আপনি লেনদেনের ইতিহাস জানার জন্য গোল্ড কিনেন অ্যাপের হোমপেইজে ইতিহাস অপশনটি দেখতে পারেন।",
//   title: "  আমি কিভাবে আমার গোল্ড কিনেন অ্যাকাউন্ট স্টেটমেন্ট পেতে পারি?"
// }, {
//   body: "আপনি আপনার নিবন্ধিত মোবাইল নম্বর পরিবর্তন করতে পারবেন না।",
//   title: "  কিভাবে কেউ গোল্ড কিনেনে তাদের নিবন্ধিত মোবাইল নম্বর পরিবর্তন করতে পারে?"
// }, {
//   body: "ব্যাংক অ্যাকাউন্ট যাচাইকরণে বিলম্বের জন্য, আপনাকে 09610964653 কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।",
//   title: "  আমি ব্যাংক অ্যাকাউন্ট যাচাইকরণে সমস্যার সম্মুখীন হচ্ছি, আমার কী করা উচিত?"
// }, {
//   body: "গোল্ড কিনেন অ্যাপের প্রোফাইল সেকশন থেকে আপনার ইমেল ঠিকানা পরিবর্তন করতে পারেন।",
//   title: "  আমি কিভাবে গোল্ড কিনেনের সাথে নিবন্ধিত আমার ইমেল ঠিকানা পরিবর্তন করব?"
// }, {
//   body: "আপনার অ্যাকাউন্ট লক হওয়ার একাধিক কারণ থাকতে পারে। বিস্তারিত জানতে, আপনাকে 09610964653 এ কল করে আমাদের কাস্টমার কেয়ার টিমের সাথে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে ।",
//   title: "  কেন আমার অ্যাকাউন্ট লক করা হয়েছে?"
// }, {
//     body: "গোল্ড কিনেন অ্যাপের অটো গোল্ড সেভ প্ল্যান হল একটি বৈশিষ্ট্য যা গোল্ড কিনেন অ্যাপ ব্যবহারকারীদের স্বয়ংক্রিয়ভাবে ২২ ক্যারাট হলমার্ক প্রত্যয়িত গোল্ড ক্রয় এবং সঞ্চয় করার সুবিধা করে দিচ্ছে। ৩, ৬ ও ১২ মাসের মেয়াদী এই সঞ্চয় প্রকল্পের সাথে ব্যবহারকারীরা, বিকাশ পেমেন্ট পদ্ধতির মাধ্যমে মাসিক ১০০০ টাকা থেকে শুরু করে ১০,০০০ টাকা পর্যন্ত সঞ্চয় করতে পারবেন।",
//     title: "অটো গোল্ড সেভ কি?"
//   },
//   {
//     body: "আপনার গোল্ড কিনেন অ্যাকাউন্টে লগ ইন করুন, হোম স্ক্রিনে অটো গোল্ড সেভ বাটনে ট্যাপ করুন, প্ল্যানের বিবরণ লিখুন, মেয়াদ নির্বাচন করুন, তথ্য যাচাই করুন, পিন প্রবেশ করুন এবং পেমেন্ট করুন বাটনে ট্যাপ করে আপনার অটো গোল্ড সেভ যাত্রা শুরু করুন।",
//     title: "কিভাবে অটো গোল্ড সেভ প্ল্যান শুরু করবেন?"
//   },
//   {
//     body: "গোল্ড কিনেন অ্যাপ, অটো গোল্ড সেভ প্ল্যান ব্যবহারকারীদের একটি মাসিক গোল্ড সেভিং স্কিম সেট আপ করতে সাহায্য করে; যেখানে ব্যবহারকারীরা একটি নির্দিষ্ট সময়ের জন্য (৩, ৬, ১২ মাস) স্বয়ংক্রিয় বিকাশ পেমেন্টের মাধ্যমে মাসিক ১০০০ টাকা থেকে শুরু করে অ্যাপের মাধ্যমে গোল্ড ক্রয় এবং সঞ্চয় করতে পারবেন।",
//     title: "অটো গোল্ড সেভ কিভাবে কাজ করে?"
//   },
//   {
//     body: "গ্রাহক ৩, ৬ এবং ১২ মাসের মেয়াদি অটো গোল্ড সেভ প্ল্যান সক্রিয় করতে পারবেন ৷",
//     title: "অটো গোল্ড সেভ-এর মেয়াদকাল কতদিনের?"
//   },
//   {
//     body: "অটো গোল্ড সেভ প্ল্যান শুরু করার জন্য সর্বনিম্ন পরিমাণ হল ১০০০ টাকা।",
//     title: "অটো গোল্ড সেভ প্ল্যান শুরু করার সর্বনিম্ন পরিমাণ কত?"
//   },
//   {
//     body: "অটো গোল্ড সেভ প্ল্যান শুরু করার সর্বোচ্চ পরিমাণ হল ১০,০০০ টাকা।",
//     title: "অটো গোল্ড সেভ শুরু করতে সর্বোচ্চ পরিমাণ কত?"
//   },
//   {
//     body: "বর্তমানে গোল্ড কিনেন অ্যাপ ব্যবহারকারীরা বিকাশ পেমেন্ট পদ্ধতি ব্যবহার করে তাদের অটো গোল্ড সেভ প্ল্যান শুরু করতে পারেন।",
//     title: "অটো গোল্ড সেভ-এর জন্য অর্থপ্রদানের পদ্ধতিগুলি কী কী?"
//   },
//   {
//     body: "ব্যবহারকারীদের একসাথে সর্বোচ্চ ৫টি অটো গোল্ড সেভ প্ল্যান সক্রিয় থাকতে পারে।",
//     title: "একসাথে কতগুলি অটো গোল্ড সেভ পরিকল্পনা সক্রিয় হতে পারে?"
//   },
//   {
//     body: "নির্ধারিত তারিখে পেমেন্ট ব্যর্থ হলে, নির্বাচিত পেমেন্ট গেটওয়ের (বিকাশ) নীতি অনুযায়ী পুনরায় পেমেন্ট করার চেষ্টা করা হবে। পেমেন্ট গেটওয়ের মেয়াদের মধ্যে যদি সমস্ত প্রচেষ্টা ব্যর্থ হয় তবে উল্লিখিত মাসের জন্য ব্যবহারকারীর অর্থপ্রদান ব্যর্থ বলে বিবেচিত হবে। তবে অটো গোল্ড সেভ প্ল্যানটি নির্ধারিত সময়ের জন্য পরবর্তী মাসে অব্যাহত থাকবে।",
//     title: "যদি অটো গোল্ড সেভ পেমেন্ট মিস করি তাহলে কি হবে?"
//   },
//   {
//     body: "আপনার গোল্ড কিনেন অ্যাকাউন্টে লগ ইন করুন, হোম স্ক্রিনে অটো গোল্ড সেভ বাটনে ট্যাপ করুন, আপনি যে প্ল্যানটি বাতিল করতে চান তার 'বিস্তারিত' বাটনে ট্যাপ করুন, স্ক্রিনের নীচে 'অটো গোল্ড সেভ প্ল্যান বাতিল করুন' বাটনে ট্যাপ করুন।",
//     title: "কিভাবে অটো গোল্ড সেভ প্ল্যান বাতিল করবেন?"
//   },
//   {
//     body: "হ্যাঁ। সরকারি নীতিমালা অনুযায়ী প্রতি মাসের গোল্ড ক্রয়ে ৫% ভ্যাট কর্তন করা হবে।",
//     title: "অটো গোল্ড সেভ প্ল্যানের অধীনে গোল্ড ক্রয় করার সময় কি ভ্যাট কর্তন করা হবে?"
//   },
//   {
//     body: "হ্যাঁ। চলমান অটো গোল্ড সেভ প্ল্যান থাকা সত্ত্বেও গ্রাহক যেকোন সময় তাদের ভল্টে সঞ্চিত গোল্ড উত্তোলন বা বিক্রয় করতে পারবেন।",
//     title: "অটো গোল্ড সেভ প্ল্যান চলমান থাকাকালীন কি আমি প্ল্যানের মাধ্যমে সঞ্চিত গোল্ড উত্তোলন বা বিক্রয় করতে পারি?"
//   },
//   {
//     body: "না। অটো গোল্ড সেভ প্ল্যান একবার ইতিমধ্যে শুরু হওয়ার পরে পরিবর্তন করা যাবে না। ব্যবহারকারীরা প্রয়োজনে বিদ্যমান প্ল্যান বাতিল করতে পারেন।",
//     title: "অটো গোল্ড সেভ প্ল্যান ইতিমধ্যে শুরু হয়ে গেলে কি মেয়াদ পরিবর্তন করা সম্ভব?"
//   }
// ];


export const refundPolicyData = [
  "The Users will be able to “Refund Gold’ back to the Company with the Gold Kinen App.. If the User receives a defective product, he can claim a refund and return the product to the company. Hence the User/Customer will be credited monetary equivalent of the amount paid by the customer for the defective product to his designated mobile wallet or bank account within three working days.",
"Upon refunding the gold back to the Company, the Company will transfer the monies for the gold at official market price of that day, as denoted in the Gold Kinen App to the User’s designated MFS / bank accounts.",
"The ‘Refund Gold’ feature is primarily a refund feature, and a refund charge will be applicable at the time of execution. The Company shall decide the refund charges, which may be subject to change upon the discretion of Company without prior notice",
"Once a ‘Refund Gold’ transaction is executed on the Gold Kinen App it shall not be reversed or stopped.",
"It shall be the sole responsibility of the Users to provide correct information in relation to their mobile wallet or bank accounts, where the refund money will be transferred in 3 (Three) business days. Furthermore, the Users solely shall be liable for providing any incorrect information in relation to their mobile wallet or bank accounts.",
"In the event of a technical error or failure during the transaction execution process, Gold Kinen would require a maximum of 5 working days to find the cause of the failure and/or rectify it.",
]

export const refundPolicyBnData = [
"অ্যাপ ব্যবহারকারীরা গোল্ড কিনেন অ্যাপের মাধ্যমে কোম্পানির কাছে “গোল্ড প্রত্যাবর্তন” (গোল্ড রিফান্ড) করতে পারবেন । যদি ব্যবহারকারী কোনো ত্রুটিপূর্ণ পণ্য পেয়ে থাকে তাহলে তিনি কোম্পনির কাছে পণ্য ফেরত দেয়ার মাধ্যমে রিফান্ড (বা প্রত্যাবর্তন) দাবি করতে পারে। এবং তিন কার্যদিবসের মধ্যে সেই ত্রুটিপূর্ণ পণ্যের জন্য ব্যবহারকারী কর্তৃক প্রদত্ত অর্থের সমপরিমাণ অর্থ তার নির্ধারিত মোবাইল ওয়ালেট বা ব্যাংক অ্যাকাউন্টে পৌঁছে (জমা) দেয়া হবে। ",
"গোল্ড কিনেনের কাছে গোল্ড প্রত্যাবর্তন (ফেরত) করার পরে, কোম্পানী সেই দিনের অফিসিয়াল বাজার মূল্যে গোল্ডের অর্থ, ব্যবহারকারীর অ্যাপে মনোনীত মোবাইল ওয়ালেট/ ব্যাংক অ্যাকাউন্টে স্থানান্তর করবে, যেমন গোল্ড কিনেন ব্যবহারকারীর অ্যাপে উল্লেখ করা হয়েছে।",
"গোল্ড রিফান্ড' ফীচারটি প্রাথমিকভাবে একটি 'প্রত্যাবর্তন/ফেরত' বৈশিষ্ট্য চার্জ, এবং এই চার্জটি 'গোল্ড রিফান্ড' লেনদেন কার্যকর করার সময় প্রযোজ্য হবে। গোল্ড কিনেন যা রিফান্ড চার্জ নির্ধারণ করবে, তা কোম্পানি-র বিবেচনার ভিত্তিতে পূর্ব ঘোষণা ছাড়াই পরিবর্তন হতে পারে।",
"একবার গোল্ড কিনেন অ্যাপে একটি 'রিফান্ড গোল্ড' লেনদেন সম্পন্ন হয়ে গেলে তা থামানো বা বাতিল করা যাবে না।",
"গোল্ড কিনেন অ্যাপ ব্যবহারকারীদের নিজস্ব মোবাইল ওয়ালেট বা ব্যাংক অ্যাকাউন্ট সম্পর্কিত সঠিক তথ্য দেয়া একান্তই গুরুত্বপূর্ণ এবং সকল তথ্যের সঠিকতা ব্যবহারকারীর নিজস্ব দায়িত্ব। ব্যবহারকারীদের প্রদত্ত মোবাইল ওয়ালেট বা ব্যাংক অ্যাকাউন্টে ৩ ব্যবসায়িক দিন /কার্যদিবসের মধ্যে প্রত্যাবর্তিত অর্থ স্থানান্তর করা হবে। তদ্ব্যতীত, ব্যবহারকারীর মোবাইল ওয়ালেট বা ব্যাংক অ্যাকাউন্ট সংক্রান্ত তথ্যে কোনো ভুল থাকলে তার জন্য শুধুমাত্র ব্যবহারকারীই দায়ী থাকবে।",
"লেনদেন সম্পাদন প্রক্রিয়া চলাকালীন একটি প্রযুক্তিগত ত্রুটি বা ব্যর্থতার ক্ষেত্রে, ব্যর্থতার কারণ খুঁজে পেতে এবং/অথবা এটি সংশোধন করতে গোল্ড কিনেনের সর্বোচ্চ ৫ কার্যদিবসের প্রয়োজন হবে।",
]


export const deliveryProtocolData = [
  "Deliveries will be made solely to delivery addresses provided by users on the Gold Kinen App.",

  "Deliveries within in Dhaka will be made within 5 working days and outside Dhaka within 10 working days once “Collect Request” is initiated.",

  "It is user’s responsibility to provide correct and valid delivery addresses. Discrepancies and errors can lead to delays and “Collect Request” cancellation.",

  "Once a “Collect Request” has been initiated to a specific address provided by the user, the address pertaining to the said “Collect Request” cannot be altered by the user.",

  "Users are required to check and confirm delivery address when initiating “Collect Request”.",

  "Deliveries maybe conducted by “Gold Kinen” through third-party vendors.",

  "Delivery charges are applicable and will be borne by the user, which may vary based on the user’s nominated delivery address.",

 "During delivery, users initiating “Collect Request” will be required to provide valid NID card for inspection by the delivery person. Once the delivery person has validated the identity of the user, only then, the product will be delivered. The delivery person may ask the user for a photo to be taken during the execution of the delivery for future reference and validation purposes.",

  "Users must check and ensure the quantity of product as expected at the time of the delivery, in the presence of the delivery person.",

  "For quality assurance, certifications will be provided with each unit of product defining the weight and purity of the gold product. For any clarifications regarding the provided certifications users are requested to contact 09610964653.",
  
  "All Gold Kinen products are delivered in a tamper-proof packaging. If a package is damaged or broken at time of delivery, users must not accept the delivery, and are requested to contact Gold Kinen customer service at 09610964653 immediately.",

  " If a product with a broken packaging or seal is accepted by the user, Gold Kinen cannot be held liable for the quality and authenticity of the product.",

  "Once product has been delivered and accepted by users it cannot be returned back to Gold Kinen.",
  
  "Users are requested to thoroughly inspect and check the product at the time of delivery.",

  "Gold Kinen users are requested to report any dispute or discrepancy at the point of delivery immediately at 09610964653 without tampering / damaging the sealed product.", 
]

export const deliveryProtocolBnData = [
  "শুধুমাত্র গোল্ড কিনেন অ্যাপ গ্রাহকদের প্রদত্ত ডেলিভারির ঠিকানায় ডেলিভারি করা হবে।",

  "“গোল্ড উত্তোলন” অনুরোধ প্রক্রিয়াধীন হওয়ার সাথে সাথে, ঢাকায় ৫ কার্যদিবসের মধ্যে এবং ঢাকার বাইরে ১০ কার্যদিবসের মধ্যে গোল্ড ডেলিভারি কার্যকর করা হবে।",

  "সঠিক এবং বৈধ ডেলিভারি ঠিকানা প্রদান করা গোল্ড কিনেন অ্যাপ গ্রাহকের নিজস্ব দায়িত্ব। গ্রাহকের প্রদত্ত তথ্যে অসঙ্গতি এবং বিলম্বের কারণে “উত্তোলন অনুরোধ” বাতিল হয়ে যেতে পারে।",

  "একবার গ্রাহকের দ্বারা প্রদত্ত একটি নির্দিষ্ট ঠিকানায় একটি “উত্তোলন অনুরোধ” শুরু করা হলে, উল্লিখিত “উত্তোলন অনুরোধ” সম্পর্কিত ঠিকানা গ্রাহক দ্বারা পরিবর্তন সম্ভব না।",

  "“উত্তোলন অনুরোধ” করার সময় গ্রাহকদের ডেলিভারির ঠিকানা পূর্ণরূপে নিশ্চিত করতে হবে।",

  "গোল্ড কিনেনের ডেলিভারি “থার্ড পার্টি ভেন্ডর” দ্বারা বিতরণ করা যেতে পারে।",

  "সকল ডেলিভারির জন্য ডেলিভারি চার্জ প্রযোজ্য এবং তা গ্রাহকের দ্বারা বহন করা হবে, যা গ্রাহকের মনোনীত ডেলিভারি ঠিকানার উপর ভিত্তি করে পরিবর্তিত হতে পারে।",

 "ডেলিভারির সময়, “উত্তোলন অনুরোধ” করা গ্রাহকদের ডেলিভারি ব্যক্তির কাছে পরিদর্শনের জন্য বৈধ NID কার্ড প্রদান করতে হবে। এখানে ডেলিভারি ব্যক্তি গ্রাহকের পরিচয় এনআইডি দ্বারা যাচাই করে নিলেই, পণ্যটি ডেলিভারি করা হবে। ডেলিভারি ব্যক্তি ভবিষ্যতের রেফারেন্স এবং বৈধতার উদ্দেশ্যে ডেলিভারি সম্পাদনের সময় গ্রাহককে একটি ছবি তোলার জন্য অনুরোধ করতে পারেন।",

  "গ্রাহকের অবশ্যই ডেলিভারি ব্যক্তির উপস্থিতিতে ডেলিভারির সময় প্রত্যাশিত পণ্যের পরিমাণ পরীক্ষা করতে হবে এবং তার সঠিকতা নিশ্চিত করতে হবে।",

  "গুণগত মান নিশ্চয়তার জন্য, গোল্ডের পণ্যের ওজন এবং বিশুদ্ধতা নির্ধারণ করে পণ্যের প্রতিটি ইউনিটের সাথে সার্টিফিকেশন প্রদান করা হবে। প্রদত্ত প্রত্যয়নপত্র সম্পর্কিত কোনো জিজ্ঞাসার জন্য গ্রাহককে 09610964653 নম্বরে যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।",
  
  "সকল গোল্ড কিনেন পণ্য একটি ট্যাম্পার-প্রুফ প্যাকেজিংয়ে সরবরাহ করা হয়। ডেলিভারির সময় কোনো প্যাকেজ ক্ষতিগ্রস্ত হলে অথবা মানসম্মত না হলে, গ্রাহককে অবশ্যই ডেলিভারি গ্রহণ না করতে বলা হচ্ছে এবং অবিলম্বে 09610964653 নম্বরে গোল্ড কিনেন গ্রাহক পরিষেবায় যোগাযোগ করার জন্য অনুরোধ করা হচ্ছে।",

  "যদি কোনো ক্ষতিগ্রস্ত সীল সহ বা অর্ধ-খোলা প্যাকেজিং সহিত একটি “পণ্য” গ্রাহক দ্বারা গৃহীত হয়, তাহলে পণ্যের গুণমান এবং নির্ভেজালত্ব-র জন্য গোল্ড কিনেনকে দায়ী করা যাবে না।",

  "একবার পণ্য সরবরাহ করা এবং গ্রাহক দ্বারা গৃহীত হলে এটি গোল্ড কিনেন-এ ফেরত দেওয়া যাবে না।",
  
  "গ্রাহকদের ডেলিভারির সময় পণ্যটি পুঙ্খানুপুঙ্খভাবে পরিদর্শন এবং পরীক্ষা করার জন্য অনুরোধ করা হচ্ছে।",

  "গোল্ড কিনেন গ্রাহকদেরকে ডেলিভারির সময় পণ্যের সিলের কোনো ক্ষতি না করেই পণ্য সংক্রান্ত যে কোনো বিবাদ বা অমিল অবিলম্বে 09610964653 নম্বরে কল করে রিপোর্ট করার জন্য অনুরোধ করা হচ্ছে।", 
]


